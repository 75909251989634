import { createUseStyles } from 'react-jss'
import { Outlet } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Col, Row } from 'react-bootstrap'
import Input from '../../../components/Input'
import Axios from '../../../utilities/axios'
import { VatNumberAPI } from '../../../http-requests'
import { FaTrash } from 'react-icons/fa'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 16,
    },
    trashIcon: {
        color: theme.palette.erp.grey3,
        cursor: 'pointer',
        textAlign: 'center',
        '&:hover': {
            color: theme.palette.erp.grey2,
        },
    },
}))

const VatNumberEdit = ({ vatNumberID, customerID, vat_number, onDelete }) => {
    const classes = useStyles()
    const { register, handleSubmit, getValues } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            vat_number,
        },
    })
    const onSubmit = async (values, id) => {
        const data = {
            ...values,
            customer: customerID,
        }
        try {
            const response = await VatNumberAPI.patch({ id, values: data })
        } catch (e) {
            console.error(e)
        }
    }

    const onDeleteClick = async () => {
        try {
            const { data } = await VatNumberAPI.delete({ id: vatNumberID })
            onDelete(vatNumberID)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <form
            onSubmit={handleSubmit((values) => onSubmit(values, vatNumberID))}
            className={classes.root}
        >
            <Row>
                <Col md={9}>
                    <Input
                        {...register('vat_number')}
                        onBlur={(event) => {
                            const val = getValues()
                            const sub = (val) => onSubmit(val, vatNumberID)
                            handleSubmit(sub(val), event)
                        }}
                    />
                </Col>
                <Col md={3} className={classes.trashIcon}>
                    <FaTrash onClick={onDeleteClick} />
                </Col>
            </Row>
        </form>
    )
}

export default VatNumberEdit
