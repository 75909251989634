import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import colors from '../theme/colors'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { CloseIcon } from '../theme/icons'
import { useWindowSize } from '../hooks/useWindowSize'

const useStyles = createUseStyles((theme) => ({
    overlay: {
        ...theme.utils.flexbox.centered,
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        overflow: 'visible',
        backgroundColor: 'rgba(31, 31, 31, 0.1)',
        backdropFilter: 'blur(4px)',
        '-webkit-backdrop-filter': 'blur(4px)',
        zIndex: 999,
    },
    root: {
        ...theme.utils.grid.centered,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        gridTemplateRows: '64px 1fr',
        gridTemplateColumns: '1fr',
        backgroundColor: theme.palette.common.white,
        borderRadius: 16,
        width: '100%',
        zIndex: 1000,
        maxWidth: ({ maxWidth }) => maxWidth,
        maxHeight: ({ maxHeight }) => maxHeight,
        [theme.mediaQueries.m]: {
            width: ({ width }) => `calc(${width}px - ${theme.spacing * 2}px`,
        },
    },
    header: ({ title }) => ({
        flexDirection: 'row-reverse',
        ...(title
            ? {
                  ...theme.utils.flexbox.spaceBetween,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
              }
            : {
                  ...theme.utils.flexbox.centered,
                  justifyContent: 'end',
              }),
        height: '100%',
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.secondary.darker,
        padding: [0, theme.spacing * 2],

        '& svg': {
            cursor: 'pointer',
            height: 24,
        },
    }),
    body: {},
}))

const Popover = ({
    onClose,
    maxWidth = 640,
    maxHeight = 800,
    children,
    title,
    className,
}) => {
    const { width } = useWindowSize()
    useEffect(() => {
        window.document.body.style['overflow-y'] = 'hidden' // lock body scroll
        return () => (window.document.body.style['overflow-y'] = 'auto') // unlock body scroll;
    }, [])

    const classes = useStyles({
        maxWidth,
        maxHeight,
        title,
        width,
    })
    return (
        <>
            <div className={classes.overlay} onClick={onClose} />
            <div className={cx(classes.root, className)}>
                <header className={classes.header}>
                    <CloseIcon onClick={onClose} />
                    {title}
                </header>
                <div className={classes.body}>{children}</div>
            </div>
        </>
    )
}

Popover.propTypes = {
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
}

export default Popover
