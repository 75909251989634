import { Navigate, Outlet } from 'react-router-dom'
import { ROUTE_HOME } from '../../utilities/constants'
import PublicLayout from '../../layouts/public-layout'
import { useSelector } from 'react-redux'
import { selectAuthToken } from '../../store/slices/app'

const PublicRoute = () => {
    const accessToken = useSelector(selectAuthToken) // TODO: add auth logic

    return accessToken ? (
        <Navigate to={ROUTE_HOME} />
    ) : (
        <PublicLayout>
            <Outlet />
        </PublicLayout>
    )
}

export default PublicRoute
