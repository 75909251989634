import { createRef, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { Controller } from 'react-hook-form'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { AlertIcon, SuccessIcon } from '../theme/icons'
import NumberFormat from 'react-number-format'

const useStyles = createUseStyles((theme) => ({
    root: {
        position: 'relative',
    },
    inputWrapper: ({ hasError }) => ({
        position: 'relative',
        width: '100%',
        '& input': {
            fontSize: 14,
            paddingTop: 10,
            paddingBottom: 10,
            borderColor: hasError ? theme.palette.error.main : null,
        },
    }),
    errorMessage: {
        color: theme.palette.error.main,
    },
}))

const MaskedInput = forwardRef(function MaskedInput(
    {
        label,
        name,
        errors,
        disabled = false,
        onValueChanged = () => void 0,
        currency = true,
        decimal = true,
        control,
        ...rest
    },
    ref
) {
    const classes = useStyles({
        hasError: !!errors,
    })
    let elRef = createRef()
    return (
        <div className={classes.root} {...rest}>
            {!!label && <label>{label}</label>}
            <div className={classes.inputWrapper}>
                <Controller
                    control={control}
                    name={name}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <NumberFormat
                            disabled={disabled}
                            value={value}
                            onValueChange={({ formattedValue, value }) => {
                                if (document.activeElement === elRef) {
                                    onValueChanged(value)
                                }
                                onChange(value)
                            }}
                            onBlur={onBlur}
                            decimalScale={decimal ? 2 : 0}
                            isNumericString={true}
                            name={name}
                            getInputRef={(el) => {
                                ref(el)
                                elRef = el
                            }}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={currency ? '€' : ''}
                            thousandsGroupStyle="thousand"
                        />
                    )}
                />
            </div>
            {errors?.message && (
                <div>
                    <small className={classes.errorMessage}>
                        {errors.message}
                    </small>
                </div>
            )}
        </div>
    )
})

export default MaskedInput

MaskedInput.propTypes = {
    className: PropTypes.any,
    label: PropTypes.string,
    type: PropTypes.oneOf(['email', 'password', 'text', 'number']),
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    iconPosition: PropTypes.oneOf(['left', 'right']),
}
