import { createUseStyles } from 'react-jss'
import { Outlet } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Col, Row } from 'react-bootstrap'
import Input from '../../../components/Input'
import Axios from '../../../utilities/axios'
import { VatNumberAPI } from '../../../http-requests'
import reset from '../../../theme/reset'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 16,
    },
}))

const VatNumberCreation = ({ customerID, onCreation }) => {
    const classes = useStyles()
    const { register, handleSubmit, getValues, reset } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            vat_number: null,
        },
    })
    const onSubmit = async (form) => {
        const values = {
            ...form,
            customer: customerID,
        }
        try {
            const { data } = await VatNumberAPI.post({ values })
            onCreation(data)
            reset()
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <form
            onSubmit={handleSubmit((values) => onSubmit(values))}
            className={classes.root}
        >
            <Row className={'align-items-center'}>
                <Col md={9}>
                    <Input {...register('vat_number')} />
                </Col>
                <Col md={3}>
                    <Button type={'submit'}>Aggiungi</Button>{' '}
                </Col>
            </Row>
        </form>
    )
}

export default VatNumberCreation
