import { createUseStyles } from 'react-jss'
import { Col, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { InvoiceAPI } from '../../http-requests'
import Invoice from '../projects/project/projectInvoices/invoice'

const useStyles = createUseStyles((theme) => ({
    root: {},
    title: {
        margin: [24, 0],
        fontSize: 20,
        fontWeight: 500,
        color: theme.palette.erp.violet1,
    },
}))

const Schedule = () => {
    const classes = useStyles()
    const [invoices, setInvoices] = useState([])

    useEffect(async () => {
        try {
            const {
                data: { results },
            } = await InvoiceAPI.index({
                status: 'Prevista',
                ordering: 'date',
            })
            console.log(results)
            setInvoices(results)
        } catch (e) {
            console.error(e)
        }
    }, [])

    return (
        <>
            <Row className={'align-items-center'}>
                <Col md={10}>
                    <h3 className={classes.title}>Scadenzario</h3>
                </Col>
            </Row>
            {invoices.length
                ? invoices.map((e) => (
                      <Invoice key={e.id} invoice={e} heading={true} />
                  ))
                : null}
        </>
    )
}

export default Schedule
