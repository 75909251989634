import { createUseStyles } from 'react-jss'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
    addActivity,
    selectActivityStatus,
    selectBusinessUnit,
} from '../../../../store/slices/project'
import dayjs from 'dayjs'
import DatePickerInput from '../../../../components/DatePicker'
import { useEffect, useRef, useState } from 'react'
import { ActivityAPI } from '../../../../http-requests'
import { retrieveSingleValueForRs } from '../../../../utilities/helpers'
import MaskedInput from '../../../../components/MaskedInput'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 32,
        border: `1px solid ${theme.palette.erp.grey4}`,
    },
    activityHeader: {
        background: theme.palette.erp.grey4,
        padding: [8, 16, 16],
    },
    save: {
        marginTop: 24,
        textAlign: 'right',
    },
}))
const NewActivity = ({
    projectID,
    project,
    onActivityCreated,
    totalBudget,
    smallFeatures,
    hourly_rate,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const spreadRef = useRef()
    const BUOptions = useSelector(selectBusinessUnit)
    const statusOptions = useSelector(selectActivityStatus)
    const firstBudget =
        totalBudget -
        project.activity_set.reduce((a, b) => a + parseFloat(b.budget), 0)
    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            title: '',
            budget: smallFeatures ? 0 : firstBudget,
            business_unit: '',
            end_date: dayjs(project.end_date).toDate(),
            start_date: dayjs(project.start_date).toDate(),
            status: retrieveSingleValueForRs(statusOptions, 'Aperto'),
            total_hours: smallFeatures
                ? 0
                : parseInt(firstBudget / hourly_rate),
        },
    })

    const {
        register,
        handleSubmit,
        setError,
        control,
        setValue,
        watch,
        getValues,
        formState: { touchedFields, errors, isSubmitting },
    } = formMethods

    const activityBudget = watch('budget', firstBudget)

    useEffect(() => {
        if (activityBudget > 0)
            spreadRef.current.value = parseFloat(
                (activityBudget / totalBudget) * 100
            ).toFixed(2)
    }, [activityBudget, totalBudget])

    const onSubmit = async (values, id) => {
        try {
            const data = {
                project: projectID,
                title: values.title,
                budget: values.budget,
                business_unit: values.business_unit.value,
                end_date: dayjs(values.end_date).format('YYYY-MM-DD'),
                start_date: dayjs(values.start_date).format('YYYY-MM-DD'),
                status: values.status.value,
                total_hours: values.total_hours,
            }
            const { data: response } = await ActivityAPI.post({ values: data })
            const { data: activity } = await ActivityAPI.get({
                id: response.id,
            })
            dispatch(addActivity(activity))
            onActivityCreated(false)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.activityHeader}>
                <form
                    onSubmit={handleSubmit((values) =>
                        onSubmit(values, projectID)
                    )}
                >
                    <Row>
                        <Col sm={smallFeatures ? 4 : 2}>
                            <Input
                                {...register('title')}
                                label="Nome attività"
                            />
                        </Col>
                        {!smallFeatures ? (
                            <Col sm={2}>
                                <MaskedInput
                                    control={control}
                                    name={'budget'}
                                    label="Budget"
                                    onValueChanged={(value) => {
                                        setValue(
                                            'total_hours',
                                            parseInt(value / hourly_rate)
                                        )
                                        setValue(
                                            'budget',
                                            parseFloat(value).toFixed(2)
                                        )
                                    }}
                                />
                            </Col>
                        ) : null}
                        {!smallFeatures ? (
                            <Col sm={1}>
                                <MaskedInput
                                    control={control}
                                    name={'total_hours'}
                                    label="Ore"
                                    currency={false}
                                    decimal={false}
                                    onValueChanged={(value) => {
                                        console.log(value)
                                        setValue(
                                            'budget',
                                            parseFloat(
                                                value * hourly_rate
                                            ).toFixed(2)
                                        )
                                    }}
                                />
                            </Col>
                        ) : null}
                        <Col sm={smallFeatures ? 2 : 1}>
                            <Select
                                name={'business_unit'}
                                label="B. Unit"
                                control={control}
                                options={BUOptions}
                            />
                        </Col>
                        <Col sm={2}>
                            <DatePickerInput
                                control={control}
                                label={'Inizio'}
                                name={'start_date'}
                            />
                        </Col>
                        <Col sm={2}>
                            <DatePickerInput
                                control={control}
                                label={'Fine'}
                                name={'end_date'}
                            />
                        </Col>
                        {!smallFeatures ? (
                            <Col sm={1}>
                                <Input
                                    disabled={true}
                                    label="Incidenza"
                                    ref={spreadRef}
                                />
                            </Col>
                        ) : null}
                        <Col sm={smallFeatures ? 2 : 1}>
                            <Select
                                name="status"
                                label="Status"
                                control={control}
                                options={statusOptions}
                            />
                        </Col>
                    </Row>
                    <div className={classes.save}>
                        <Button type="submit" size="sm">
                            Salva
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewActivity
