import { createUseStyles } from 'react-jss'
import { useEffect, useState } from 'react'
import {
    ForecastAPI,
    getForecast,
    getProjects,
    ProjectAPI,
} from '../../http-requests'
import moment from 'moment'
import { Table } from 'react-bootstrap'
import ProjectCell from './projectCell'
import CustomerCell from './customerCell'
import { ROUTE_PROJECT } from '../../utilities/constants'
import { Link } from 'react-router-dom'

const useStyles = createUseStyles((theme) => ({
    root: {
        '& td': {
            padding: [16, 8],
        },
    },
    customerRow: {
        background: theme.palette.erp.grey5,
        fontWeight: 700,
    },
}))

const Consumptives = () => {
    const classes = useStyles()
    const [projectsList, setProjects] = useState([])
    const [customersMap, setCustomersMap] = useState({})
    const [forecastsList, setForecasts] = useState([])
    const [forecastsObject, setForecastsObject] = useState({})
    const [dates, setDates] = useState([])

    useEffect(async () => {
        const today = new Date()
        const start = moment(
            `01/${today.getMonth() + 1}/${today.getFullYear()}`,
            'DD/MM/YYYY'
        )
            .subtract(1, 'months')
            .format('YYYY-MM-DD')
        const end = moment(start).add(11, 'months').format('YYYY-MM-DD')

        const {
            data: { results: projects },
        } = await ProjectAPI.index({ type: 'Consuntivo', status: 'Aperto' })

        const {
            data: { results: forecast },
        } = await ForecastAPI.index({
            date_after: start,
            date_before: end,
            limit: 5000,
        })
        setProjects(projects)
        setForecasts(forecast)
    }, [])

    useEffect(() => {
        let projectCustomerMap = {}
        let newCustomersMap = {}
        const today = new Date()
        const start = moment(
            `01/${today.getMonth() + 1}/${today.getFullYear()}`,
            'DD/MM/YYYY'
        ).subtract(1, 'months')
        const end = moment(start).add(11, 'months')
        const monthN = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
        let months = []
        if (projectsList.length) {
            monthN.map((e) => {
                //const mont = moment(start).add(e, 'months').format('MM-YY');
                const mont = moment(start).add(e, 'months')
                months.push(mont)
            })
            projectsList.map((e) => {
                //init object for current project
                newCustomersMap[e.customer.id] = e.customer
                projectCustomerMap[e.customer.id]
                    ? (projectCustomerMap[e.customer.id][e.id] = {
                          project: e,
                          forecast: [],
                      })
                    : (projectCustomerMap[e.customer.id] = {
                          [e.id]: { project: e, forecast: [] },
                      })
                //push forecast
                for (let x = 0; x < months.length; x++) {
                    if (
                        months[x].isBetween(
                            moment(e.start_date, 'YYYY-MM-DD'),
                            moment(e.end_date, 'YYYY-MM-DD'),
                            undefined,
                            []
                        )
                    ) {
                        const forecastElement = forecastsList.filter(
                            (fore) =>
                                fore.project === e.id &&
                                moment(fore.date, 'YYYY-MM-DD').get('month') ===
                                    months[x].get('month') &&
                                moment(fore.date, 'YYYY-MM-DD').get('year') ===
                                    months[x].get('year')
                        )

                        if (forecastElement.length) {
                            projectCustomerMap[e.customer.id][
                                e.id
                            ].forecast.push({
                                ...forecastElement[0],
                                budget: parseFloat(forecastElement[0].budget),
                            })
                        } else {
                            projectCustomerMap[e.customer.id][
                                e.id
                            ].forecast.push({
                                project: e.id,
                                date: months[x].format('YYYY-MM-DD'),
                            })
                        }
                    } else {
                        projectCustomerMap[e.customer.id][e.id].forecast.push({
                            disabled: true,
                            project: e.id,
                            date: months[x].format('YYYY-MM-DD'),
                        })
                    }
                }
            })
        }
        setForecastsObject(projectCustomerMap)
        setCustomersMap(newCustomersMap)
        setDates(months)
    }, [forecastsList])

    const onChangeValue = ({ forecast, customerID, forecastIndex }) => {
        const newObj = { ...forecastsObject }
        newObj[customerID][forecast.project].forecast[forecastIndex] = {
            ...forecast,
            budget: parseFloat(forecast.budget),
        }
        setForecastsObject(newObj)
    }

    const renderRows = () => {
        const rows = []
        const customersIDs = Object.keys(forecastsObject)
        for (let x = 0; x < customersIDs.length; x++) {
            const customerID = customersIDs[x]
            const customerRow = (
                <tr
                    key={`customer-${customerID}`}
                    className={classes.customerRow}
                >
                    <td key={`customer-${customerID}-name`}>
                        {customersMap[customerID].business_name}
                    </td>
                    {dates.map((e, index) => (
                        <CustomerCell
                            key={`customer-${customerID}-${index}`}
                            dateRef={e}
                            projectMap={forecastsObject[customerID]}
                        />
                    ))}
                </tr>
            )
            rows.push(customerRow)
            const projectIDs = Object.keys(forecastsObject[customerID])
            for (let y = 0; y < projectIDs.length; y++) {
                const projectID = projectIDs[y]
                const projectRow = (
                    <tr key={`project-${projectID}`}>
                        <td key={`project-${projectID}-name`}>
                            <Link
                                target="_blank"
                                to={ROUTE_PROJECT.replace(
                                    ':projectID',
                                    projectID
                                )}
                            >
                                {
                                    forecastsObject[customerID][projectID]
                                        .project.title
                                }
                            </Link>
                        </td>
                        {dates.map((e, index) => (
                            <ProjectCell
                                key={`project-${projectID}-${index}`}
                                onChangeValue={onChangeValue}
                                customerID={customerID}
                                forecastIndex={index}
                                forecast={
                                    forecastsObject[customerID][projectID]
                                        .forecast[index]
                                }
                            />
                        ))}
                    </tr>
                )
                rows.push(projectRow)
            }
        }
        return rows
    }

    return (
        <div>
            <Table className={classes.root}>
                <thead>
                    <tr>
                        <th>Cliente / Progetto</th>
                        {dates.map((e, index) => (
                            <th key={index}>{e.format('M-YY').toString()}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>{renderRows()}</tbody>
            </Table>
        </div>
    )
}

export default Consumptives
