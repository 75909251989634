import { createUseStyles } from 'react-jss'
import { Link, Outlet, useOutletContext } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import CustomerPayments from './customerPayments'
import CustomerResume from './customerResume'
import CustomerAdjustments from './customerAdjustment'

const useStyles = createUseStyles((theme) => ({
    root: {},
    tabsNavigation: {
        marginTop: 32,
    },
}))

const CustomerTabs = ({ view = 'projects' }) => {
    const classes = useStyles()
    const { customerID } = useOutletContext()

    const renderComponentTab = () => {
        switch (view) {
            case 'payments':
                return <CustomerPayments />
            case 'adjustment':
                return <CustomerAdjustments />
            case 'resume':
                return <CustomerResume />
        }
    }

    return (
        <div>
            <div className={classes.tabsNavigation}>
                <Nav
                    variant="tabs"
                    defaultActiveKey={`/projects/${customerID}`}
                >
                    <Nav.Item>
                        <Nav.Link
                            active={view === 'payments'}
                            as={Link}
                            to={`/customers/${customerID}`}
                        >
                            Fatture emesse
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            active={view === 'adjustment'}
                            as={Link}
                            to={`/customers/${customerID}/adjustment`}
                        >
                            Rettifiche
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            active={view === 'resume'}
                            as={Link}
                            to={`/customers/${customerID}/resume`}
                        >
                            Resoconto
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            {renderComponentTab()}
        </div>
    )
}

export default CustomerTabs
