import React from 'react'
import { render } from 'react-dom'
import { createBrowserHistory } from 'history'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import store from './store'
import { Provider } from 'react-redux'
import './index.scss'
import { jss } from 'react-jss'
import { axiosAttachInterceptors } from './utilities/axios/interceptors'
const { requestInterceptor, responseInterceptor } = axiosAttachInterceptors()

jss.setup({
    id: { minify: true },
})

const history = createBrowserHistory({ basename: '/' })

render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history} basename={'/'}>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
