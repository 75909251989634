import { useRoutes } from 'react-router'
import * as routeNames from './utilities/constants/routeNames'
import Home from './pages/home'
import PageNotFound from './pages/page-not-found'
import PublicRoute from './route-guards/public-route'
import Customers from './pages/customers'
import Customer from './pages/customers/customer'
import CustomerTabs from './pages/customers/customer/customerTabs'
import Projects from './pages/projects'
import Project from './pages/projects/project'
import ProjectTabs from './pages/projects/project/projectTabs'
import Consumptives from './pages/consumptives'
import Schedule from './pages/schedule'
import Login from './pages/login'
import PrivateRoute from './route-guards/private-route'
import NFT from './pages/nft'
import CreateProject from './pages/projects/project/createProject'

const AppRoutes = () =>
    useRoutes([
        {
            element: <PublicRoute />, // Anonymous User Layout Route
            children: [
                {
                    exact: true,
                    path: routeNames.ROUTE_LOGIN,
                    element: <Login />,
                },
                {
                    path: '*', // Not found route
                    element: <PageNotFound />,
                },
            ],
        },
        {
            element: <PrivateRoute />, // Anonymous User Layout Route
            children: [
                {
                    exact: true,
                    path: routeNames.ROUTE_HOME,
                    element: <Home />,
                },
                {
                    exact: true,
                    path: routeNames.ROUTE_CUSTOMERS,
                    element: <Customers />,
                },
                {
                    exact: true,
                    path: routeNames.ROUTE_CUSTOMER,
                    element: <Customer />,
                    children: [
                        {
                            exact: true,
                            path: '',
                            element: <CustomerTabs view="payments" />,
                        },
                        {
                            exact: true,
                            path: 'adjustment',
                            element: <CustomerTabs view="adjustment" />,
                        },
                        {
                            exact: true,
                            path: 'resume',
                            element: <CustomerTabs view="resume" />,
                        },
                    ],
                },
                {
                    exact: true,
                    path: routeNames.ROUTE_PROJECTS,
                    element: <Projects />,
                },
                {
                    exact: true,
                    path: routeNames.ROUTE_PROJECT_CREATE,
                    element: <CreateProject />,
                },
                {
                    exact: true,
                    path: routeNames.ROUTE_PROJECT,
                    element: <Project />,
                    children: [
                        {
                            exact: true,
                            path: '',
                            element: <ProjectTabs />,
                        },
                        {
                            exact: true,
                            path: 'invoices',
                            element: <ProjectTabs view="invoices" />,
                        },
                        {
                            exact: true,
                            path: 'expenses',
                            element: <ProjectTabs view="expenses" />,
                        },
                        {
                            exact: true,
                            path: 'resume',
                            element: <ProjectTabs view="resume" />,
                        },
                    ],
                },
                {
                    exact: true,
                    path: routeNames.ROUTE_CONSUMPTIVES,
                    element: <Consumptives />,
                },
                {
                    exact: true,
                    path: routeNames.ROUTE_SCHEDULE,
                    element: <Schedule />,
                },
            ],
        },
    ])

export default AppRoutes
