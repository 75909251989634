import { createUseStyles } from 'react-jss'
import DatePicker from 'react-datepicker'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { it } from 'date-fns/esm/locale'
import { useController } from 'react-hook-form'
import cx from 'classnames'

registerLocale('it', it)

const useStyles = createUseStyles((theme) => ({
    root: ({ hasError }) => ({
        '& input': {
            fontSize: 14,
            paddingTop: 10,
            paddingBottom: 10,
            borderColor: hasError ? theme.palette.error.main : null,
        },
    }),
    errorMessage: {
        color: theme.palette.error.main,
    },
}))

const DatePickerInput = ({
    control,
    label,
    name,
    onValueChanged = () => void 0,
    placeholder,
    disabled = false,
    helpText,
    touched = false,
    errors,
    className,
    ...rest
}) => {
    const {
        field: { onChange, value, ...fieldProps },
        fieldState: { error, isTouched, ...fieldStateProps },
        formState,
    } = useController({
        name,
        control,
        defaultValue: '',
    })
    const classes = useStyles({
        touched,
        isSuccess: !errors && touched,
        hasError: !!errors,
    })
    return (
        <div {...rest} className={classes.root}>
            {!!label && <label htmlFor={name}>{label}</label>}
            <div>
                <DatePicker
                    disabled={disabled}
                    locale="it"
                    dateFormat={'dd-MM-yyyy'}
                    selected={value}
                    onChange={(event) => {
                        onChange(event)
                        onValueChanged(event)
                    }}
                    {...fieldStateProps}
                />
            </div>
            {errors?.message && (
                <div>
                    <small className={classes.errorMessage}>
                        {errors.message}
                    </small>
                </div>
            )}
        </div>
    )
}

export default DatePickerInput
