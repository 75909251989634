import { createUseStyles } from 'react-jss'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { useFieldArray, useForm } from 'react-hook-form'
import { FaTrash, FaRegCheckCircle } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { retrieveSingleValueForRs } from '../../../../utilities/helpers'
import dayjs from 'dayjs'
import DatePickerInput from '../../../../components/DatePicker'
import { MilestoneAPI } from '../../../../http-requests'
import Checkbox from '../../../../components/Checkbox'
import Popover from '../../../../components/Popover'
import MaskedInput from '../../../../components/MaskedInput'
import { useDispatch } from 'react-redux'
import { addMilestone } from '../../../../store/slices/project'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 16,
        padding: [0, 16],
    },
    heading: {
        padding: [32, 0, 24, 0],
    },
    title: {
        color: theme.palette.erp.grey1,
        fontWeight: 700,
        fontSize: 16,
    },
    addBtn: {
        textAlign: 'right',
    },
    milestone: {
        margin: [24, 0],
    },
    save: {
        marginTop: 16,
        textAlign: 'center',
    },
    trashIcon: {
        color: theme.palette.erp.grey3,
        cursor: 'pointer',
        textAlign: 'center',
        '&:hover': {
            color: theme.palette.erp.grey2,
        },
    },
}))

const NewMilestone = ({
    activityID,
    onCreation = () => void 0,
    defaultWeight = 0,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            weight: defaultWeight,
        },
    })
    const {
        register,
        control,
        handleSubmit,
        formState: { touchedFields, errors, isSubmitting },
    } = formMethods

    const onSubmit = async (values) => {
        try {
            const payload = {
                ...values,
                execution_date: values.execution_date
                    ? dayjs(values.execution_date).format('YYYY-MM-DD')
                    : null,
                weight: parseFloat(values.weight / 100),
                activity: activityID,
            }
            const { data } = await MilestoneAPI.post({
                values: payload,
            })
            console.log(data, activityID)
            dispatch(
                addMilestone({
                    activityID,
                    milestone: data,
                })
            )
            onCreation(data)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <Form
                className={classes.root}
                onSubmit={handleSubmit((values) => onSubmit(values))}
            >
                <div className={classes.milestone}>
                    <Row className="align-items-center">
                        <Col md={12}>
                            <Input
                                {...register(`title`)}
                                label={'Descrizione'}
                            />
                        </Col>
                        <Col md={12}>
                            <MaskedInput
                                control={control}
                                name={'weight'}
                                label={'Riparto'}
                                currency={false}
                                decimal={false}
                            />
                        </Col>
                        <Col md={12}>
                            <DatePickerInput
                                control={control}
                                name={`execution_date`}
                                label={'Deadline'}
                            />
                        </Col>
                        <Col md={12} className={classes.save}>
                            <Button type="submit" size="sm">
                                Salva
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}

export default NewMilestone
