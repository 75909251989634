import { createUseStyles } from 'react-jss'
import { useTable, useSortBy } from 'react-table'
import { Button, Col, Row, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { CustomerAPI, getCustomers } from '../../http-requests'
import {
    TiArrowSortedDown,
    TiArrowSortedUp,
    TiArrowUnsorted,
} from 'react-icons/ti'
import { Link } from 'react-router-dom'
import {
    ROUTE_CUSTOMER,
    ROUTE_CUSTOMER_CREATE,
    ROUTE_PROJECT_CREATE,
} from '../../utilities/constants'
import ReactTable from '../../components/ReactTable'
import Axios from '../../utilities/axios'
import Popover from '../../components/Popover'
import NewCustomer from './customer/newCustomer'

const useStyles = createUseStyles((theme) => ({
    root: {},
    title: {
        margin: [24, 0],
        fontSize: 20,
        fontWeight: 500,
        color: theme.palette.erp.violet1,
    },
}))

const Customers = () => {
    const classes = useStyles()
    const [customers, setCustomers] = useState([])
    const [customerCreation, setCustomerCreation] = useState(false)
    const [nextUrl, setNextUrl] = useState(null)
    const [hasMore, setHasMore] = useState(true)
    const columns = [
        {
            Header: '#',
            accessor: 'id', // accessor is the "key" in the data
        },
        {
            Header: 'Ragione sociale',
            accessor: 'business_name', // accessor is the "key" in the data,
            Cell: ({
                row: {
                    values: { id, business_name },
                },
            }) => (
                <Link to={ROUTE_CUSTOMER.replace(':customerID', id)}>
                    {business_name}
                </Link>
            ),
        },
        {
            Header: 'P.iva',
            accessor: 'vat',
        },
    ]
    useEffect(async () => {
        getItems()
    }, [])

    const getItems = async () => {
        let response
        if (hasMore) {
            if (!nextUrl) {
                response = await CustomerAPI.index()
            } else if (nextUrl) {
                response = await Axios.get(nextUrl)
            }
            const {
                data: { results, next },
            } = response
            const customers = results.map(
                ({ business_name, vatnumber_set, id }) => {
                    return {
                        business_name,
                        vat: vatnumber_set.map((e) => e.vat_number).toString(),
                        id,
                    }
                }
            )
            setCustomers((prev) => [...prev, ...customers])
            if (next) {
                setNextUrl(next)
            } else {
                setNextUrl(false)
                setHasMore(false)
            }
        }
    }

    const onToggleCreate = () => {
        setCustomerCreation(!customerCreation)
    }

    return customers.length ? (
        <div>
            <Row className={'align-items-center'}>
                <Col md={10}>
                    <h3 className={classes.title}>Clienti</h3>
                </Col>
                <Col>
                    <Button onClick={onToggleCreate}>Crea cliente</Button>
                </Col>
            </Row>
            <ReactTable
                columns={columns}
                data={customers}
                hasMore={hasMore}
                update={getItems}
            />
            {customerCreation ? (
                <Popover onClose={onToggleCreate}>
                    <NewCustomer />
                </Popover>
            ) : null}
        </div>
    ) : null
}

export default Customers
