import { createUseStyles } from 'react-jss'
import { Outlet, useLocation } from 'react-router-dom'
import { useParams } from 'react-router'
import Popover from '../../../components/Popover'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Axios from '../../../utilities/axios'
import { useForm } from 'react-hook-form'
import Input from '../../../components/Input'
import VatNumberEdit from './vatNumberEdit'
import TextArea from '../../../components/TextArea'
import { CustomerAPI, getCustomer, patchCustomer } from '../../../http-requests'
import VatNumberCreation from './vatNumberCreation'

const useStyles = createUseStyles((theme) => ({
    root: {
        paddingTop: 32,
        paddingBottom: 32,
    },
}))

const Customer = () => {
    const classes = useStyles()
    const { pathname } = useLocation()
    const { customerID } = useParams()
    const [customer, setCustomer] = useState()
    const { register, handleSubmit, setValue, getValues } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            note: '',
        },
    })

    useEffect(async () => {
        const { data } = await CustomerAPI.get({ id: customerID })
        setValue('note', data.note)
        setCustomer(data)
    }, [customerID])

    const onSubmit = async (values) => {
        try {
            const { data } = await CustomerAPI.patch({ id: customerID, values })
        } catch (e) {
            console.error(e)
        }
    }
    const onVatNumberCreation = (entity) => {
        const newCustomer = {
            ...customer,
            vatnumber_set: [...customer.vatnumber_set, entity],
        }
        setCustomer(newCustomer)
    }
    const onVatNumberDelete = (id) => {
        const index = customer.vatnumber_set.findIndex((e) => e.id === id)
        const newCustomer = {
            ...customer,
        }
        newCustomer.vatnumber_set.splice(index, 1)
        setCustomer(newCustomer)
    }
    return (
        <Container className={classes.root}>
            <Row>
                <Col>
                    <h1>{customer?.business_name}</h1>
                </Col>
            </Row>
            <Row>
                <Col lg={5}>
                    <label>Partita iva</label>
                    {customer?.vatnumber_set.map(
                        ({ id, vat_number }, index) => (
                            <VatNumberEdit
                                key={id}
                                vatNumberID={id}
                                onDelete={onVatNumberDelete}
                                customerID={customerID}
                                vat_number={vat_number}
                            />
                        )
                    )}
                    <VatNumberCreation
                        customerID={customerID}
                        onCreation={onVatNumberCreation}
                    />
                </Col>
                <Col md={{ offset: 1 }}>
                    <form
                        onSubmit={handleSubmit((values) =>
                            onSubmit(values, customerID)
                        )}
                    >
                        <TextArea
                            label={'note'}
                            {...register('note')}
                            onBlur={(event) => {
                                const val = getValues()
                                const sub = (val) => onSubmit(val)
                                handleSubmit(sub(val), event)
                            }}
                        />
                    </form>
                </Col>
            </Row>

            <Outlet context={{ customerID }} />
        </Container>
    )
}

export default Customer
