import { createUseStyles } from 'react-jss'
import { useEffect, useState } from 'react'
import Axios from 'axios'

const useStyles = createUseStyles((theme) => ({
    root: {},
}))

const NFT = () => {
    const classes = useStyles()
    const [nftList, setNftList] = useState([])
    useEffect(async () => {
        const { data } = await Axios.get('http://192.168.86.30:5000/api/nft')
        const list = data.map((el) => el[0])
        list.forEach((e) => {
            const response = Axios.get(e)
            response.then((value) => {
                const { permalink, orders } = value.data
            })
        })
    }, [])
    return <div>Daje</div>
}

export default NFT
