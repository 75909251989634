import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    root: {},
}))

const Homepage = () => {
    const classes = useStyles()

    return <div>Homepage</div>
}

export default Homepage
