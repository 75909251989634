import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import {
    ROUTE_CUSTOMER,
    ROUTE_PROJECT,
    ROUTE_PROJECT_CREATE,
} from '../../utilities/constants'
import ReactTable from '../../components/ReactTable'
import { useEffect, useState } from 'react'
import Axios from '../../utilities/axios'
import { getProjects, ProjectAPI } from '../../http-requests'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import {
    selectCustomers,
    selectProjectStatus,
    selectProjectType,
    selectReferrals,
} from '../../store/slices/project'
import { useSelector } from 'react-redux'
import Select from '../../components/Select'
import Input from '../../components/Input'
import MaskedInput from '../../components/MaskedInput'
import NumberFormat from 'react-number-format'
import { retrieveSingleValueForRs } from '../../utilities/helpers'

const useStyles = createUseStyles((theme) => ({
    root: {},
    title: {
        margin: [24, 0],
        fontSize: 20,
        fontWeight: 500,
        color: theme.palette.erp.violet1,
    },
    filters: {
        marginBottom: 24,
    },
    filterButton: {
        marginBottom: 4,
    },
}))

const Projects = () => {
    const classes = useStyles()
    const [projects, setProjects] = useState([])
    const [nextUrl, setNextUrl] = useState(null)
    const [hasMore, setHasMore] = useState(true)
    const filterType = useSelector(selectProjectType)
    const filterStatus = useSelector(selectProjectStatus)
    const filterReferral = useSelector(selectReferrals)
    const filterCustomer = useSelector(selectCustomers)

    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            customer: null,
            title: null,
            status: retrieveSingleValueForRs(filterStatus, 'Aperto'),
            type: null,
            referral: null,
        },
    })
    const {
        register,
        handleSubmit,
        watch,
        setError,
        control,
        setValue,
        getValues,
        reset,
        formState: { touchedFields, errors, isSubmitting },
    } = formMethods

    const columns = [
        {
            Header: '#',
            accessor: 'id', // accessor is the "key" in the data
        },
        {
            Header: 'Cliente',
            accessor: 'customer.business_name', // accessor is the "key" in the data,
            Cell: ({
                row: {
                    original: {
                        customer: { id, business_name },
                    },
                },
            }) => (
                <Link to={ROUTE_CUSTOMER.replace(':customerID', id)}>
                    {business_name}
                </Link>
            ),
        },
        {
            Header: 'Nome progetto',
            accessor: 'title',
            Cell: ({
                row: {
                    values: { id, title },
                },
            }) => (
                <Link to={ROUTE_PROJECT.replace(':projectID', id)}>
                    {title}
                </Link>
            ),
        },
        {
            Header: 'Stato',
            accessor: 'status',
        },
        {
            Header: 'Tipologia',
            accessor: 'type',
        },
        {
            Header: 'Referente',
            accessor: 'referral.username',
            Cell: ({
                row: {
                    original: { referral },
                },
            }) =>
                referral
                    ? `${referral.first_name} ${referral.last_name}`
                    : null,
        },
        {
            Header: 'Inizio',
            accessor: 'start_date',
        },
        {
            Header: 'Fine',
            accessor: 'end_date',
        },
        {
            Header: 'Budget',
            accessor: 'total_budget',
            Cell: ({
                row: {
                    original: { total_budget },
                },
            }) =>
                total_budget ? (
                    <NumberFormat
                        value={total_budget}
                        decimalScale={2}
                        isNumericString={true}
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType={'text'}
                        prefix={'€'}
                        thousandsGroupStyle="thousand"
                    />
                ) : null,
        },
    ]

    useEffect(async () => {
        getItems({ params: { status: 'Aperto' }, isFirst: true })
    }, [])

    const getItems = async ({ params, isFirst }) => {
        let response
        if (isFirst) {
            response = await ProjectAPI.index(params)
            const {
                data: { results, next },
            } = response
            setProjects((prev) => [...results])
            if (next) {
                setNextUrl(next)
            } else {
                setNextUrl(false)
                setHasMore(false)
            }
        } else {
            if (hasMore) {
                if (!nextUrl) {
                    response = await ProjectAPI.index(params)
                } else if (nextUrl) {
                    response = await Axios.get(nextUrl)
                }
                const {
                    data: { results, next },
                } = response
                setProjects((prev) => [...prev, ...results])
                if (next) {
                    setNextUrl(next)
                } else {
                    setNextUrl(false)
                    setHasMore(false)
                }
            }
        }
    }

    const onSubmit = (values) => {
        const params = {}
        Object.keys(values).map((key) => {
            params[key] = values[key]
                ? values[key].value
                    ? values[key].value
                    : values[key]
                : null
        })
        getItems({ params, isFirst: true })
    }

    return (
        <div>
            <Row className={'align-items-center'}>
                <Col md={10}>
                    <h3 className={classes.title}>Progetti</h3>
                </Col>
                <Col>
                    <Link to={ROUTE_PROJECT_CREATE}>
                        <Button>Crea progetto</Button>
                    </Link>
                </Col>
            </Row>
            <div className={classes.filters}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className={'align-items-end'}>
                        <Col>
                            <Input
                                {...register('title')}
                                label={'Nome progetto'}
                                name={'title'}
                            />
                        </Col>
                        <Col>
                            <Select
                                label={'Cliente'}
                                isClearable={'true'}
                                control={control}
                                name={'customer'}
                                options={filterCustomer}
                            />
                        </Col>
                        <Col>
                            <Select
                                label={'Stato'}
                                isClearable={'true'}
                                control={control}
                                name={'status'}
                                options={filterStatus}
                            />
                        </Col>
                        <Col>
                            <Select
                                label={'Tipologia'}
                                isClearable={'true'}
                                control={control}
                                name={'type'}
                                options={filterType}
                            />
                        </Col>
                        <Col>
                            <Select
                                label={'Referente'}
                                isClearable={'true'}
                                control={control}
                                name={'referral'}
                                options={filterReferral}
                            />
                        </Col>
                        <Col>
                            <Button
                                className={classes.filterButton}
                                type={'submit'}
                            >
                                Filtra
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
            {projects.length ? (
                <ReactTable
                    data={projects}
                    columns={columns}
                    hasMore={hasMore}
                    update={getItems}
                />
            ) : null}
        </div>
    )
}

export default Projects
