import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { sumAPI } from '../../../../http-requests'
import { setAlertMessage } from '../../../../store/slices/app'
import { useDispatch } from 'react-redux'
import Input from '../../../../components/Input'
import Select from '../../../../components/Select'
import { useForm } from 'react-hook-form'
import DatePickerInput from '../../../../components/DatePicker'
import dayjs from 'dayjs'

const useStyles = createUseStyles((theme) => ({
    root: {},
    filters: {
        marginBottom: 24,
    },
    filterButton: {
        marginBottom: 4,
    },
}))

const CustomerResume = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { customerID } = useParams()
    const [dateFilter, setDateFilter] = useState()
    // 0 Invoice, 1 Expense, 2 Log, 3 Adjustment
    const [totals, setTotals] = useState([0, 0, 0, 0])

    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            date: dayjs().toDate(),
        },
    })
    const { handleSubmit, control } = formMethods

    const fetchData = async () => {
        const promiseInvoice = new Promise(async (resolve, reject) => {
            try {
                const { data } = await sumAPI.invoice({
                    date_before: dateFilter,
                    customer: customerID,
                    without_project: true,
                })
                resolve(parseFloat(data.hours).toFixed(2))
            } catch (e) {
                reject(e)
            }
        })
        const promiseExpense = new Promise(async (resolve, reject) => {
            try {
                const { data } = await sumAPI.expense({
                    date_before: dateFilter,
                    customer: customerID,
                    project_type: 'Consuntivo',
                })
                resolve(parseFloat(data.hours).toFixed(2))
            } catch (e) {
                reject(e)
            }
        })
        const promiseLog = new Promise(async (resolve, reject) => {
            try {
                const { data } = await sumAPI.log({
                    date_before: dateFilter,
                    customer: customerID,
                    project_type: 'Consuntivo',
                })
                const seconds = parseFloat(data.seconds)
                const hours = seconds ? seconds / 60 / 60 : 0
                resolve(hours.toFixed(2))
            } catch (e) {
                reject(e)
            }
        })
        const promiseAdjustment = new Promise(async (resolve, reject) => {
            try {
                const { data } = await sumAPI.adjustment({
                    date_before: dateFilter,
                    customer: customerID,
                    project_type: 'Consuntivo',
                })
                resolve(parseFloat(data.hours).toFixed(2))
            } catch (e) {
                reject(e)
            }
        })

        try {
            const response = await Promise.all([
                promiseInvoice,
                promiseExpense,
                promiseLog,
                promiseAdjustment,
            ])
            setTotals(response)
        } catch (e) {
            console.error(e)
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message: 'Dati resoconto non caricati, errore dalle API.',
                })
            )
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        fetchData()
    }, [dateFilter])

    const onSubmit = (values) => {
        setDateFilter(dayjs(values.date).format('YYYY-MM-DD'))
    }

    return (
        <Container className={classes.root}>
            <div className={classes.filters}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className={'align-items-end'}>
                        <Col>
                            <DatePickerInput
                                control={control}
                                label={'Fino al'}
                                name={'date'}
                            />
                        </Col>
                        <Col>
                            <Button
                                className={classes.filterButton}
                                type={'submit'}
                            >
                                Filtra
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
            <p>Ore fatturate: {totals[0]}</p>
            <p>Ore pagate a fornitori: {totals[1]}</p>
            <p>Ore lavorate: {totals[2]}</p>
            <p>Ore rettificate: {totals[3]}</p>
            <p>Consuntivo: {totals[0] - totals[1] - totals[2] - totals[3]}</p>
        </Container>
    )
}

export default CustomerResume
