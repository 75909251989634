import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    root: {},
}))

const ProjectResume = () => {
    const classes = useStyles()

    return <div>Resoconto</div>
}

export default ProjectResume
