import { createUseStyles } from 'react-jss'
import ProjectActivities from './projectActivities'
import ProjectInvoices from './projectInvoices'
import ProjectExpenses from './projectExpenses'
import ProjectResume from './projectResume'

const useStyles = createUseStyles((theme) => ({
    root: {
        paddingTop: 16,
    },
}))

const ProjectTabs = ({ view = 'activities' }) => {
    const classes = useStyles()

    const renderComponentTab = () => {
        switch (view) {
            case 'activities':
                return <ProjectActivities />
            case 'invoices':
                return <ProjectInvoices />
            case 'expenses':
                return <ProjectExpenses />
            case 'resume':
                return <ProjectResume />
        }
    }
    return <div className={classes.root}>{renderComponentTab()}</div>
}

export default ProjectTabs
