import React, { useCallback } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useController } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import ReactSelect, { components } from 'react-select'
import { DownArrowIcon } from '../theme/icons'

const useStyles = createUseStyles((theme) => ({
    root: {
        position: 'relative',
    },
    label: {},
    errorMessage: {
        color: theme.palette.error.main,
    },
}))

const Select = ({
    control,
    name,
    options,
    label,
    placeholder,
    helpText,
    defaultValue = '',
    isSearchable = false,
    isClearable = false,
    isCreatable = false,
    addOptionMessage,
    maxItems = 100,
    isMulti,
    onChangeCallback,
    closeMenuOnSelect = true,
    ...props
}) => {
    const {
        field: { onChange, ...fieldProps },
        fieldState: { error, isTouched, ...fieldStateProps },
        formState,
    } = useController({
        name,
        control,
        defaultValue,
    })

    // This extend the defaulst onChange
    const onChangeHandler = (value) => {
        if (typeof onChangeCallback === 'function') onChangeCallback(value)
        if (!isMulti || (isMulti && value.length <= maxItems)) onChange(value) // Limit multi select
    }

    // Label for new item creation
    const createLabel = useCallback(
        (value) => (
            <span style={{ fontSize: 14 }}>
                {addOptionMessage}
                <span>{value}</span>
            </span>
        ),
        [addOptionMessage]
    )

    const classes = useStyles()
    const theme = useTheme()
    //React select styles - https://react-select.com/styles
    const reactSelectsStyle = {
        placeholder: (defaults, state) => ({
            ...defaults,
            color: theme.palette.grey[400],
            fontWeight: 400,
            fontSize: 14,
            margin: isMulti ? '0 10px' : 0,
        }),
        valueContainer: (defaults, state) => ({
            ...defaults,
            fontSize: 14,
            padding: 0,
            gap: 4,
        }),
        singleValue: (defaults, { isDisabled, isFocused }) => ({
            ...defaults,
            ...props?.singleValueCustomStyle,
        }),
        control: (defaults, { isDisabled, isFocused }) => ({
            ...defaults,
            ...theme.controls.input,
            padding: isMulti ? '6px 16px 6px 6px' : '8px',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            borderColor: `${theme.palette.grey[300]} !important`,
            ...(isDisabled && {
                borderColor: `${theme.palette.grey[200]} !important`,
            }),
            ...(isFocused && {
                borderColor: `${theme.palette.tertiary.main} !important`,
            }),
            ...(error && {
                borderColor: `${theme.palette.error.main} !important`,
                svg: {
                    fill: theme.palette.error.main,
                },
            }),
            ...(!error &&
                isTouched &&
                !props.disabled && {
                    borderColor: `${theme.palette.success.main} !important`,
                    svg: {
                        fill: theme.palette.success.main,
                    },
                }),
            boxShadow: isFocused ? `0 0 0 2px #333333` : `none`,
        }),
        option: (provided, { isDisabled, isFocused, isSelected }) => ({
            ...provided,
            color: isDisabled
                ? theme.palette.grey[200]
                : theme.palette.tertiary.main,
            padding: `8px 16px`,
            ...((isFocused || isSelected) && {
                backgroundColor: `${theme.palette.tertiary.light} !important`,
            }),
            ...(isSelected && { fontWeight: 700 }),
            ...props?.optionCustomStyle,
            fontSize: 14,
        }),
        indicatorsContainer: (defaults) => ({
            ...defaults,
            display: 'flex',
            alignItems: 'start',
            backgroundColor: 'transparent',
            padding: isMulti ? '8px 0' : 0,
            '& svg': {
                fill: theme.palette.grey[300],
            },
        }),
        indicatorSeparator: (defaults) => ({
            ...defaults,
            width: 0,
            padding: 0,
        }),
        menu: (defaults) => ({
            ...defaults,
            zIndex: 20,
            overflow: 'hidden',
        }),
        menuList: (defaults) => ({
            ...defaults,
            padding: 0,
            margin: 8,
        }),
        dropdownIndicator: (defaults) => ({
            ...defaults,
            padding: '2px',
        }),
        clearIndicator: (defaults) => ({
            ...defaults,
            marginRight: 4,
            padding: 0,
            backgroundColor: 'transparent',
        }),
        multiValueLabel: (defaults) => ({
            ...defaults,
            textTransform: isCreatable ? 'uppercase' : 'none',
            padding: 0,
            ...props?.multiValueLabelCustomStyle,
        }),
        multiValue: (defaults, state) => ({
            ...defaults,
            margin: 0,
            ...props?.multiValueCustomStyle,
        }),
        multiValueRemove: (defaults, state) => ({
            display: 'flex',
            alignItems: 'center',
            padding: '0 4px',
        }),
    }

    const selectProps = {
        options,
        closeMenuOnSelect,
        isSearchable,
        isClearable,
        isMulti,
        isDisabled: props.readOnly || props.disabled,
        className: classes.select,
        classNamePrefix: isCreatable ? 'creatable_select' : 'select',
        styles: reactSelectsStyle,
        placeholder,
        ...props,
    }

    return (
        <div className={classes.root}>
            {!!label && <label>{label}</label>}
            {isCreatable ? (
                <CreatableSelect
                    formatCreateLabel={createLabel}
                    //components={{...components, DropdownIndicator}}
                    onChange={onChangeHandler}
                    {...formState} // from Controller
                    {...fieldProps} // from Controller
                    {...selectProps} // from Component
                />
            ) : (
                <ReactSelect
                    //components={{...components, DropdownIndicator}}
                    onChange={onChangeHandler}
                    {...formState}
                    {...fieldProps}
                    {...selectProps}
                />
            )}
            {error?.message && (
                <div>
                    <small className={classes.errorMessage}>
                        {error.message}
                    </small>
                </div>
            )}
            {helpText && (
                <div>
                    <small>{helpText}</small>
                </div>
            )}
        </div>
    )
}

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <DownArrowIcon />
        </components.DropdownIndicator>
    )
}

export default Select
