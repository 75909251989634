import { ThemeProvider } from 'react-jss'
import { useSelector } from 'react-redux'
import { theme } from './theme'
import { selectTheme } from './store/slices/app'
import AppRoutes from './routes'
import SharedLayout from './layouts/shared-layout'
import dayjs from 'dayjs'
dayjs.locale('it-IT')

const App = () => {
    const themeName = useSelector(selectTheme)

    return (
        <ThemeProvider theme={theme({ name: themeName })}>
            <SharedLayout>
                <AppRoutes />
            </SharedLayout>
        </ThemeProvider>
    )
}

export default App
