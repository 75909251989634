import { createUseStyles } from 'react-jss'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { useForm } from 'react-hook-form'
import { selectInvoiceStatus } from '../../../../store/slices/project'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { InvoiceAPI, ProjectAPI } from '../../../../http-requests'
import { useOutletContext } from 'react-router-dom'
import Invoice from './invoice'
import MaskedInput from '../../../../components/MaskedInput'
import DatePickerInput from '../../../../components/DatePicker'
import dayjs from 'dayjs'
import { setAlertMessage } from '../../../../store/slices/app'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 32,
    },
    activityHeader: {
        background: theme.palette.erp.grey4,
        padding: [8, 16, 16],
    },
    save: {
        marginTop: 24,
        textAlign: 'right',
    },
}))
const ProjectInvoices = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { projectID } = useOutletContext()
    const [invoices, setInvoices] = useState({})
    const [customer, setCustomer] = useState({})
    const statusOptions = useSelector(selectInvoiceStatus)
    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
    })
    const {
        register,
        handleSubmit,
        setError,
        control,
        reset,
        setValue,
        getValues,
        formState: { touchedFields, errors, isSubmitting },
    } = formMethods

    useEffect(async () => {
        try {
            const {
                data: { invoice_set, customer: customerData },
            } = await ProjectAPI.accounting({ id: projectID })
            setInvoices(invoice_set)
            setCustomer(customerData)
        } catch (e) {
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message:
                        'Non siamo riusciti a caricare la lista delle fatture. Qualcosa è andato storto.',
                })
            )
            console.error(e)
        }
    }, [])

    const onSubmit = async (formData) => {
        const values = {
            ...formData,
            project: projectID,
            customer: customer.id,
            date: formData.date
                ? dayjs(formData.date).format('YYYY-MM-DD')
                : null,
            payment_date: formData.payment_date
                ? dayjs(formData.payment_date).format('YYYY-MM-DD')
                : null,
            status: formData.status.value,
        }
        try {
            const { data } = await InvoiceAPI.post({
                values,
            })
            setInvoices((prevState) => [...prevState, data])
            reset()
            setValue('amount', 0)
        } catch (e) {
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message:
                        'Non siamo riusciti a creare la fattura. Qualcosa è andato storto.',
                })
            )
            console.error(e)
        }
    }
    const onInvoiceDelete = async (id) => {
        try {
            const { data } = InvoiceAPI.delete({ id })
            const index = invoices.findIndex((e) => e.id === id)
            const newInvoiceList = [...invoices]
            newInvoiceList.splice(index, 1)
            setInvoices(newInvoiceList)
        } catch (e) {
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message: 'Fattura non cancellata.',
                })
            )
            console.error(e)
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.activityHeader}>
                <form
                    onSubmit={handleSubmit((values) =>
                        onSubmit(values, projectID)
                    )}
                >
                    <Row className="align-items-center">
                        <Col sm={3}>
                            <DatePickerInput
                                control={control}
                                label={'Data emissione'}
                                name={'date'}
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                name={'amount'}
                                label="Imponibile"
                            />
                        </Col>
                        <Col sm={2}>
                            <Select
                                name="status"
                                label="Status"
                                control={control}
                                options={statusOptions}
                            />
                        </Col>
                        <Col sm={3}>
                            <Input
                                {...register('description')}
                                label="Descrizione"
                            />
                        </Col>
                        <Col sm={1}>
                            <div className={classes.save}>
                                <Button type="submit" size="sm">
                                    Salva
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
            {invoices.length
                ? invoices.map((e) => (
                      <Invoice
                          key={e.id}
                          invoice={e}
                          onDelete={onInvoiceDelete}
                      />
                  ))
                : null}
        </div>
    )
}

export default ProjectInvoices
