import { createUseStyles } from 'react-jss'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ROUTE_CUSTOMER, ROUTE_PROJECT } from '../../../../utilities/constants'
import DatePickerInput from '../../../../components/DatePicker'
import MaskedInput from '../../../../components/MaskedInput'
import { FaTrash } from 'react-icons/fa'
import { retrieveSingleValueForRs } from '../../../../utilities/helpers'
import dayjs from 'dayjs'
import {
    AdjustmentAPI,
    ExpenseAPI,
    InvoiceAPI,
} from '../../../../http-requests'
import { setAlertMessage } from '../../../../store/slices/app'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 16,
        paddingBottom: 16,
        borderBottom: `2px solid ${theme.palette.erp.grey4}`,
    },
    activityHeader: {
        padding: [8, 16, 16],
    },
    save: {
        marginTop: 24,
        textAlign: 'right',
    },
    paymentDate: {
        marginBottom: 14,
        fontSize: 14,
    },
    iconDelete: {
        marginBottom: 14,
        fontSize: 14,
        color: theme.palette.erp.violet1,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.erp.violet2,
        },
    },
    info: {
        '& a:first-child': {
            paddingRight: 16,
        },
        '& a:first-child:after': {
            content: "'/'",
            position: 'relative',
            left: 8,
        },
    },
}))
const CustomerAdjustment = ({ adjustment, projects, onDelete }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            project: retrieveSingleValueForRs(
                projects,
                adjustment.project.id || adjustment.project
            ),
            execution_date: dayjs(adjustment.execution_date).toDate(),
            total_hours: parseFloat(adjustment.total_hours),
            title: adjustment.title,
        },
    })
    const { register, handleSubmit, setError, control, getValues } = formMethods
    const onSubmit = async (formData) => {
        const values = {
            ...formData,
            execution_date: formData.execution_date
                ? dayjs(formData.execution_date).format('YYYY-MM-DD')
                : null,
            project: formData.project.value,
        }
        try {
            const { data } = await AdjustmentAPI.patch({
                id: adjustment.id,
                values,
            })
            dispatch(
                setAlertMessage({
                    variant: 'success',
                    message: 'Rettifica salvata',
                })
            )
        } catch (e) {
            console.error(e)
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message: 'Non siamo riusciti ad aggiornare la rettifica.',
                })
            )
        }
    }
    const onClickDelete = () => {
        onDelete(adjustment.id)
    }
    return (
        <div className={classes.root}>
            <div className={classes.activityHeader}>
                <form onSubmit={handleSubmit((values) => onSubmit(values))}>
                    <Row className="align-items-end">
                        <Col sm={3}>
                            <Input
                                {...register('title')}
                                onBlur={(event) => {
                                    const val = getValues()
                                    const sub = (val) => onSubmit(val)
                                    handleSubmit(sub(val), event)
                                }}
                                label="Titolo"
                            />
                        </Col>
                        <Col sm={2}>
                            <DatePickerInput
                                control={control}
                                onValueChanged={(value) => {
                                    const item = getValues()
                                    item.date = value
                                    const sub = (item) => onSubmit(item)
                                    handleSubmit(sub(item))
                                }}
                                label={'Data'}
                                name={'execution_date'}
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                onBlur={(event) => {
                                    const val = getValues()
                                    const sub = (val) => onSubmit(val)
                                    handleSubmit(sub(val), event)
                                }}
                                name={'total_hours'}
                                currency={false}
                                decimal={false}
                                label="Ore"
                            />
                        </Col>
                        <Col sm={3}>
                            <Select
                                name={'project'}
                                label="Progetto"
                                control={control}
                                onBlur={(event) => {
                                    const val = getValues()
                                    const sub = (val) => onSubmit(val)
                                    handleSubmit(sub(val), event)
                                }}
                                options={projects}
                            />
                        </Col>

                        <Col sm={1}>
                            <div className={classes.iconDelete}>
                                <FaTrash onClick={onClickDelete} />
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default CustomerAdjustment
