import { createUseStyles } from 'react-jss'
import { Button, Col, Row } from 'react-bootstrap'
import Activity from './activity'
import { useOutletContext } from 'react-router-dom'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import NewActivity from './NewActivity'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    addActivity,
    getCurrentProject,
} from '../../../../store/slices/project'
import NumberFormat from 'react-number-format'
import ActivityBadge from './activityBadge'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    root: {},
    milestones: {},
    addBtn: {
        textAlign: 'right',
    },
    addActivity: {
        margin: [8, 0, 24, 0],
    },
}))

const ProjectActivities = () => {
    const classes = useStyles()
    const project = useSelector(getCurrentProject)
    const { projectID } = useOutletContext()
    const [newEntryActivity, setNewEntryActivity] = useState(false)
    const [totalContingencyBudget, setTotalContingencyBudget] = useState(0)
    const [totalActivityBudget, setTotalActivityBudget] = useState(0)
    const [totalActivityHours, setTotalActivityHours] = useState(0)
    const [totalContingencyActivityHours, setTotalContingencyActivityHours] =
        useState(0)
    const onActivityCreated = (status) => {
        setNewEntryActivity(status)
    }

    useEffect(() => {
        const newTotalActivityBudget = project?.activity_set.reduce(
            (a, b) => a + parseFloat(b.budget),
            0
        )
        const newTotalActivityHours = project?.activity_set.reduce(
            (a, b) => a + parseFloat(b.total_hours),
            0
        )

        const newTotalContingecyBudget =
            parseFloat(project.total_budget) -
            parseFloat(project.total_budget) * parseFloat(project.contingency)

        const newTotalContingecyHours =
            parseInt(project.total_hours) -
            parseInt(project.total_hours) * parseFloat(project.contingency)

        setTotalContingencyBudget(newTotalContingecyBudget.toFixed(2))
        setTotalActivityHours(newTotalActivityHours)
        setTotalActivityBudget(newTotalActivityBudget)
        setTotalContingencyActivityHours(parseInt(newTotalContingecyHours))
    }, [project])

    return (
        <div className={classes.root}>
            <Row className={cx(classes.addActivity, 'align-items-end')}>
                <Col md={8}>
                    {project.type !== 'Consuntivo' ? (
                        <ActivityBadge
                            totalActivities={totalActivityBudget}
                            totalBudget={totalContingencyBudget}
                            totalHours={totalContingencyActivityHours}
                            totalHoursAssigned={totalActivityHours}
                        />
                    ) : null}
                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>
                    <Button onClick={() => onActivityCreated(true)}>
                        <AiOutlinePlusCircle /> Aggiungi attività
                    </Button>
                </Col>
            </Row>
            {newEntryActivity && (
                <NewActivity
                    projectID={projectID}
                    project={project}
                    hourly_rate={project.hourly_rate}
                    totalBudget={totalContingencyBudget}
                    smallFeatures={project.type === 'Consuntivo'}
                    onActivityCreated={onActivityCreated}
                />
            )}
            {project?.activity_set?.map((activity, index) => (
                <Activity
                    key={activity.id}
                    activity={activity}
                    hourly_rate={project.hourly_rate}
                    totalBudget={totalContingencyBudget}
                    smallFeatures={project.type === 'Consuntivo'}
                    projectID={projectID}
                />
            ))}
        </div>
    )
}

export default ProjectActivities
