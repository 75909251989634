import { createUseStyles } from 'react-jss'
import { ProgressBar } from 'react-bootstrap'
import NumberFormat from 'react-number-format'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: [16],
        border: `1px solid ${theme.palette.erp.grey4}`,
        background: theme.palette.erp.grey5,
    },
    heading: {
        display: 'grid',
        gridTemplateColumns: 'auto max-content',
        alignItems: 'baseline',
        marginBottom: 8,
    },
    label: {
        fontSize: 16,
    },
    spread: {
        fontSize: 20,
        color: theme.palette.erp.violet2,
        fontWeight: 800,
    },
    bar: {
        height: 8,
        '& .progress-bar': {
            background: theme.palette.erp.violet2,
        },
    },
    totalBudget: {
        marginTop: 16,
        paddingTop: 16,
        borderTop: `1px solid ${theme.palette.erp.grey4}`,
        display: 'grid',
        gridTemplateColumns: 'auto max-content',
        alignItems: 'baseline',
        '& div:first-child': {
            fontSize: 14,
        },
    },
}))

const ActivityBadge = ({
    totalBudget,
    totalActivities,
    totalHours = 0,
    totalHoursAssigned = 0,
}) => {
    const classes = useStyles()

    const spread = parseFloat((totalActivities / totalBudget) * 100).toFixed(2)

    return (
        <>
            <div className={classes.root}>
                <div className={classes.heading}>
                    <div className={classes.label}>
                        Totale assegnato{' '}
                        <small>
                            ({totalHoursAssigned} h - rimangono{' '}
                            {totalHours - totalHoursAssigned} h)
                        </small>
                    </div>
                    <div className={classes.spread}>{spread}%</div>
                </div>
                <ProgressBar now={spread} className={classes.bar} />
                <div className={classes.totalBudget}>
                    <div>
                        Totale budget <small>({totalHours} h)</small>
                    </div>
                    <div>
                        <NumberFormat
                            value={parseFloat(totalBudget)}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="€"
                            displayType={'text'}
                            thousandsGroupStyle="thousand"
                        />
                    </div>
                </div>
            </div>
            {/*<p>{totalBudget}</p>
            <p>{totalActivities}</p>*/}
        </>
    )
}

export default ActivityBadge
