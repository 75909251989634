import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import {
    AdjustmentAPI,
    ExpenseAPI,
    InvoiceAPI,
    ProjectAPI,
} from '../../../../http-requests'
import { useDispatch, useSelector } from 'react-redux'
import { selectInvoiceStatus } from '../../../../store/slices/project'
import { useForm } from 'react-hook-form'
import { setAlertMessage } from '../../../../store/slices/app'
import dayjs from 'dayjs'
import DatePickerInput from '../../../../components/DatePicker'
import MaskedInput from '../../../../components/MaskedInput'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import Invoice from '../../../projects/project/projectInvoices/invoice'
import * as PropTypes from 'prop-types'
import CustomerAdjustment from './adjustment'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 32,
    },
    activityHeader: {
        background: theme.palette.erp.grey4,
        padding: [8, 16, 16],
    },
    save: {
        marginTop: 24,
        textAlign: 'right',
    },
}))

const CustomerAdjustments = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { customerID } = useParams()
    const [adjustments, setAdjustments] = useState([])
    const [projects, setProjects] = useState([])
    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
    })
    const {
        register,
        handleSubmit,
        setError,
        control,
        reset,
        setValue,
        getValues,
        formState: { touchedFields, errors, isSubmitting },
    } = formMethods

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {
                    data: { results: adjustmentsList },
                } = await AdjustmentAPI.index({
                    customer: customerID,
                })

                const {
                    data: { results: projectsList },
                } = await ProjectAPI.index({
                    customer: customerID,
                    type: 'Consuntivo',
                })

                setProjects(
                    projectsList.map((item) => {
                        return {
                            value: item.id,
                            label: item.title,
                        }
                    })
                )
                setAdjustments(adjustmentsList)
            } catch (e) {
                console.error(e)
                dispatch(
                    setAlertMessage({
                        variant: 'fail',
                        message: 'Non siamo riusciti a caricare le rettifiche.',
                    })
                )
            }
        }
        fetchData()
    }, [])
    const onSubmit = async (formData) => {
        const values = {
            ...formData,
            customer: customerID,
            execution_date: formData.execution_date
                ? dayjs(formData.execution_date).format('YYYY-MM-DD')
                : null,
            project: formData.project.value,
        }
        try {
            const { data } = await AdjustmentAPI.post({
                values,
            })
            setAdjustments((prevState) => [...prevState, data])
            reset()
            setValue('total_hours', 0)
        } catch (e) {
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message: 'Non siamo riusciti a creare la rettifica.',
                })
            )
            console.error(e)
        }
    }
    const onAdjustmentDelete = async (id) => {
        try {
            const { data } = AdjustmentAPI.delete({ id })
            const index = adjustments.findIndex((e) => e.id === id)
            const newAdjustmentList = [...adjustments]
            newAdjustmentList.splice(index, 1)
            setAdjustments(newAdjustmentList)
        } catch (e) {
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message: 'Rettifica non cancellata.',
                })
            )
            console.error(e)
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.activityHeader}>
                <form onSubmit={handleSubmit((values) => onSubmit(values))}>
                    <Row className="align-items-center">
                        <Col sm={3}>
                            <Input {...register('title')} label="Titolo" />
                        </Col>
                        <Col sm={2}>
                            <DatePickerInput
                                control={control}
                                label={'Data'}
                                name={'execution_date'}
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                currency={false}
                                decimal={false}
                                name={'total_hours'}
                                label="Ore"
                            />
                        </Col>
                        <Col sm={3}>
                            <Select
                                name={'project'}
                                label="Progetto"
                                control={control}
                                isSearchable={true}
                                options={projects}
                            />
                        </Col>
                        <Col sm={1}>
                            <div className={classes.save}>
                                <Button type="submit" size="sm">
                                    Salva
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
            {adjustments.length
                ? adjustments.map((e) => (
                      <CustomerAdjustment
                          key={e.id}
                          adjustment={e}
                          projects={projects}
                          onDelete={onAdjustmentDelete}
                      />
                  ))
                : null}
        </div>
    )
}

export default CustomerAdjustments
