import { createUseStyles } from 'react-jss'
import { Button, Col, Container, Nav, Row } from 'react-bootstrap'
import Input from '../../../components/Input'
import { useEffect, useState } from 'react'
import Select from '../../../components/Select'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectCustomers,
    selectProjectStatus,
    selectProjectType,
    selectReferrals,
    storeProject,
} from '../../../store/slices/project'
import { CustomerAPI, ProjectAPI, UserAPI } from '../../../http-requests'
import { retrieveSingleValueForRs } from '../../../utilities/helpers'
import DatePicker from '../../../components/DatePicker'
import dayjs from 'dayjs'
import MaskedInput from '../../../components/MaskedInput'
import Axios from 'axios'
import { ROUTE_PROJECT } from '../../../utilities/constants'
import { useNavigate } from 'react-router-dom'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginTop: 32,
    },
    editIcons: {
        cursor: 'pointer',
        color: theme.palette.grey[400],
        '&:hover': {
            color: theme.palette.grey[600],
        },
    },
    tabsNavigation: {
        marginTop: 32,
    },
}))

const CreateProject = (location, state) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const customers = useSelector(selectCustomers)
    const referralOptions = useSelector(selectReferrals)
    const statusOptions = useSelector(selectProjectStatus)
    const typeOptions = useSelector(selectProjectType)
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        reset,
        formState: { touchedFields, errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            title: '',
            type: retrieveSingleValueForRs(typeOptions, 'Stimato'),
            status: retrieveSingleValueForRs(statusOptions, 'Aperto'),
            contingency: 30,
            start_date: dayjs().toDate(),
            end_date: dayjs().toDate(),
            total_budget: 0,
            total_hours: 0,
            hourly_rate: 45,
        },
    })
    const projectType = useWatch({ control, name: 'type' })
    const projectTotalBudget = watch('total_budget', 0)
    const projectTotalHours = watch('total_hours', 0)

    useEffect(() => {
        if (
            projectTotalBudget &&
            projectTotalHours &&
            ['Stimato', 'Pre-sale'].includes(projectType?.value)
        ) {
            const hourly_rate = projectTotalBudget / projectTotalHours || 0

            setValue('hourly_rate', parseFloat(hourly_rate).toFixed(2))
        }
    }, [projectTotalBudget, projectTotalHours])

    const onSubmit = async (values) => {
        const projectData = {
            ...values,
            start_date: dayjs(values.start_date).format('YYYY-MM-DD'),
            end_date: dayjs(values.end_date).format('YYYY-MM-DD'),
            status: values?.status?.value,
            type: values?.type?.value,
            customer: values?.customer?.value,
            referral: values?.referral?.value,
            total_budget:
                values?.type?.value !== 'Consuntivo' ? values.total_budget : 0,
            total_hours:
                values?.type?.value !== 'Consuntivo' ? values.total_hours : 0,
            contingency:
                values?.type?.value !== 'Consuntivo'
                    ? values.contingency > 100
                        ? 1
                        : values.contingency / 100
                    : 0,
            hourly_rate: parseFloat(values.hourly_rate),
        }
        try {
            const { data } = await ProjectAPI.post({
                values: projectData,
            })
            navigate(ROUTE_PROJECT.replace(':projectID', data.id))
        } catch (e) {
            console.error(e)
        }
    }

    return customers.length ? (
        <div className={classes.root}>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <Input
                                {...register('title')}
                                label="Nome progetto"
                            />
                        </Col>
                        <Col>
                            <Select
                                isSearchable={true}
                                name={'customer'}
                                label="Cliente"
                                control={control}
                                options={customers}
                            />
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button type={'submit'}>Salva</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <Select
                                name={'type'}
                                label="Tipologia"
                                control={control}
                                options={typeOptions}
                            />
                        </Col>
                        <Col sm={3}>
                            <DatePicker
                                label="Data inizio"
                                control={control}
                                name={'start_date'}
                            />
                        </Col>
                        <Col sm={3}>
                            <DatePicker
                                label="Data fine"
                                control={control}
                                name={'end_date'}
                            />
                        </Col>
                        <Col sm={2}>
                            <Select
                                name={'status'}
                                label="Stato"
                                control={control}
                                options={statusOptions}
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                currency={false}
                                decimal={false}
                                disabled={projectType?.value === 'Consuntivo'}
                                name={'contingency'}
                                label="Contingenza %"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                name={'total_budget'}
                                disabled={projectType?.value === 'Consuntivo'}
                                label="Budget"
                            />
                        </Col>
                        <Col sm={2}>
                            <Input
                                {...register('total_hours')}
                                disabled={projectType?.value === 'Consuntivo'}
                                label="Totale ore"
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                name={'hourly_rate'}
                                currency={false}
                                disabled={projectType?.value !== 'Consuntivo'}
                                label="Costo orario"
                            />
                        </Col>
                        <Col sm={3}>
                            <Select
                                name={'referral'}
                                label="Referente"
                                control={control}
                                options={referralOptions}
                            />
                        </Col>
                        <Col sm={3}>
                            <Input {...register('note')} label="Link drive" />
                        </Col>
                    </Row>
                </form>
            </Container>
        </div>
    ) : (
        <p>loading...</p>
    )
}

export default CreateProject
