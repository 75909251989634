import { createUseStyles } from 'react-jss'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { useFieldArray, useForm } from 'react-hook-form'
import { FaTrash, FaRegCheckCircle } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { retrieveSingleValueForRs } from '../../../../utilities/helpers'
import dayjs from 'dayjs'
import DatePickerInput from '../../../../components/DatePicker'
import { MilestoneAPI } from '../../../../http-requests'
import Checkbox from '../../../../components/Checkbox'
import Popover from '../../../../components/Popover'
import NewMilestone from './newMilestone'
import { BsPlusCircle } from 'react-icons/bs'
import MaskedInput from '../../../../components/MaskedInput'
import { setAlertMessage } from '../../../../store/slices/app'
import { useDispatch } from 'react-redux'
import {
    removeMilestone,
    updateMilestone,
} from '../../../../store/slices/project'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 16,
        padding: [0, 16],
    },
    heading: {
        padding: [32, 0, 24, 0],
    },
    title: {
        color: theme.palette.erp.grey1,
        fontWeight: 700,
        fontSize: 16,
    },
    addBtn: {
        textAlign: 'right',
        '& svg': {
            marginRight: 8,
        },
    },
    milestone: {
        margin: [24, 0],
    },
    trashIcon: {
        color: theme.palette.erp.grey3,
        cursor: 'pointer',
        textAlign: 'center',
        '&:hover': {
            color: theme.palette.erp.grey2,
        },
    },
    checkbox: {
        display: 'inline-grid',
    },
    totalWeight: {
        textAlign: 'center',
        color: theme.palette.erp.grey3,
        fontSize: 16,
    },
}))

const Milestone = ({ elements = [], activityID }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [addMilestone, setAddMilestone] = useState(false)

    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            milestones: [],
        },
    })
    const {
        register,
        handleSubmit,
        setError,
        reset,
        setValue,
        control,
        getValues,
        formState: { touchedFields, errors, isSubmitting },
    } = formMethods

    const { fields, append } = useFieldArray({
        control,
        name: 'milestones',
    })

    useEffect(() => {
        setForm(elements)
    }, [])

    const setForm = (data) => {
        const milestones = [...data]
            .sort((a, b) => a.blocked - b.blocked)
            .map((item) => {
                return {
                    ...item,
                    execution_date: item.execution_date
                        ? dayjs(item.execution_date).toDate()
                        : '',
                    weight:
                        item.weight <= 1
                            ? parseFloat(item.weight) * 100
                            : item.weight,
                    status:
                        typeof item.status === 'string'
                            ? item.status !== 'Aperto'
                            : item.status,
                }
            })
        reset({ milestones })
        setList(milestones)
    }

    const onSubmit = async (values, index) => {
        try {
            const item = { ...values }
            const payload = {
                ...item,
                execution_date: item.execution_date
                    ? dayjs(item.execution_date).format('YYYY-MM-DD')
                    : null,
                weight: parseFloat(item.weight / 100),
                activity: list[index].activity,
                status: item.status ? 'Chiuso' : 'Aperto',
                delivery_date: item.status
                    ? dayjs().format('YYYY-MM-DD')
                    : null,
                blocked: item.status || list[index].blocked ? true : false,
                id: list[index].id,
            }
            const { data } = await MilestoneAPI.patch({
                id: payload.id,
                values: payload,
            })

            dispatch(
                updateMilestone({
                    activityID: activityID,
                    milestoneID: payload.id,
                    milestone: data,
                })
            )

            const findIndex = list.findIndex((e) => e.id === list[index].id)
            const newData = [...list]
            newData[findIndex] = { ...data }
            setForm(newData)
            dispatch(
                setAlertMessage({
                    variant: 'success',
                    message: 'Progetto salvato',
                })
            )
        } catch (e) {
            console.error(e)
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message: 'Qualcosa non è andato nel verso giusto.',
                })
            )
        }
    }

    const onDelete = (index) => async () => {
        try {
            const id = list[index].id
            const { data } = MilestoneAPI.delete({ id })
            const newData = [...list]
            newData.splice(index, 1)
            dispatch(removeMilestone({ activityID, milestoneID: id }))
            setForm(newData)
        } catch (e) {
            console.error(e)
        }
    }
    const onNewMilestone = (dataToPush) => {
        const newData = [...list, dataToPush]
        setForm(newData)
        onTogglNewMilestone()
    }
    const onTogglNewMilestone = () => {
        setAddMilestone(!addMilestone)
    }

    return (
        <>
            <div className={classes.root}>
                <Form>
                    <Row className={classes.heading}>
                        <Col>
                            <p className={classes.title}>Milestones</p>
                        </Col>
                        <Col className={classes.addBtn}>
                            <a onClick={onTogglNewMilestone}>
                                <BsPlusCircle />
                                Aggiungi Milestone
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>Descrizione</Col>
                        <Col md={1}>Riparto</Col>
                        <Col md={2}>Deadline</Col>
                    </Row>
                    {fields.map((item, index) => (
                        <div className={classes.milestone} key={item.id}>
                            <Row className="align-items-center">
                                <Col md={5}>
                                    <Input
                                        {...register(
                                            `milestones.${index}.title`
                                        )}
                                        disabled={item.blocked}
                                        onBlur={(event) => {
                                            const val = getValues()
                                            const sub = (val) =>
                                                onSubmit(
                                                    val.milestones[index],
                                                    index
                                                )
                                            handleSubmit(sub(val), event)
                                        }}
                                    />
                                </Col>
                                <Col md={1}>
                                    <MaskedInput
                                        control={control}
                                        disabled={item.blocked}
                                        name={`milestones.${index}.weight`}
                                        currency={false}
                                        decimal={false}
                                        onBlur={(event) => {
                                            const val = getValues()
                                            const sub = (val) =>
                                                onSubmit(
                                                    val.milestones[index],
                                                    index
                                                )
                                            handleSubmit(sub(val), event)
                                        }}
                                    />
                                </Col>
                                <Col md={2}>
                                    <DatePickerInput
                                        control={control}
                                        onValueChanged={(value) => {
                                            const val = getValues()
                                            const item = val.milestones[index]
                                            item.execution_date = value
                                            const sub = (item) =>
                                                onSubmit(item, index)
                                            handleSubmit(sub(item))
                                        }}
                                        name={`milestones.${index}.execution_date`}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Checkbox
                                        {...register(
                                            `milestones.${index}.status`
                                        )}
                                        disabled={item.blocked && item.status}
                                        label={
                                            item.status &&
                                            list[index]?.delivery_date
                                        }
                                        className={classes.checkbox}
                                        onChange={(event) => {
                                            const val = getValues()
                                            val.milestones[index].status =
                                                !val.milestones[index].status
                                            const sub = (val) =>
                                                onSubmit(
                                                    val.milestones[index],
                                                    index
                                                )
                                            handleSubmit(sub(val), event)
                                        }}
                                    />
                                </Col>
                                <Col md={1} className={classes.trashIcon}>
                                    {!item.blocked && (
                                        <FaTrash onClick={onDelete(index)} />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Form>
                <Row>
                    <Col
                        md={{ span: 1, offset: 5 }}
                        style={{ textAlign: 'center' }}
                    >
                        <p className={classes.totalWeight}>
                            {fields.reduce((a, b) => a + b.weight, 0)}% Tracked
                        </p>
                    </Col>
                </Row>
            </div>
            {addMilestone ? (
                <Popover
                    maxWidth={640}
                    maxHeight={800}
                    onClose={onTogglNewMilestone}
                    title={'Crea una milestone'}
                >
                    <NewMilestone
                        activityID={activityID}
                        defaultWeight={
                            100 - fields.reduce((a, b) => a + b.weight, 0)
                        }
                        onCreation={onNewMilestone}
                    />
                </Popover>
            ) : null}
        </>
    )
}

export default Milestone
