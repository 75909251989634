import { createUseStyles } from 'react-jss'
import { Button, Container } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Input from '../../components/Input'
import logo from '../../logo.svg'
import { AuthAPI } from '../../http-requests'
import { handleApiError } from '../../utilities/helpers'
import { useDispatch } from 'react-redux'
import { setAuthToken } from '../../store/slices/app'
const useStyles = createUseStyles((theme) => ({
    root: {
        background: theme.palette.erp.violet1,
        minHeight: '100vh',
        marginLeft: -12,
        marginRight: -12,
    },
    formWrapper: {
        maxWidth: 640,
        position: 'absolute',
        margin: [0, 'auto'],
        top: '25%',
        left: 0,
        right: 0,
        '& > img': {
            margin: [0, 'auto', 32, 'auto'],
            position: 'relative',
            display: 'block',
        },
        '& form': {
            background: theme.palette.background.body,
            padding: 16,
            borderRadius: 8,
        },
    },
    saveButton: {
        marginTop: 24,
        textAlign: 'center',
    },
}))

const Login = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        control,
        reset,
        formState: { touchedFields, errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            username: null,
            password: null,
        },
    })
    const onSubmit = async (values) => {
        try {
            const { data } = await AuthAPI.post({ values })
            dispatch(setAuthToken(data.token))
        } catch (e) {
            handleApiError({
                isReduxError: false,
                error: e,
                callbackOnFieldError: setError,
            })
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.formWrapper}>
                <img src={logo} alt="" />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        label={'Username'}
                        errors={errors.username}
                        {...register('username')}
                    />
                    <Input
                        label={'Password'}
                        type={'password'}
                        errors={errors.password}
                        {...register('password')}
                    />
                    <div className={classes.saveButton}>
                        <Button type={'submit'}>Accedi</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login
