import Axios from '../utilities/axios'

export const UserAPI = {
    index: () => Axios.get('/user'),
}

export const BusinessUnitAPI = {
    index: () => Axios.get('/business_unit'),
}

export const ProjectAPI = {
    index: (params) => Axios.get('/project', { params: params }),
    get: ({ id }) => Axios.get(`/project/${id}`),
    post: ({ values }) => Axios.post(`/project/create`, values),
    patch: ({ id, values }) => Axios.patch(`/project/update/${id}`, values),
    accounting: ({ id }) => Axios.get(`/project/${id}/accounting`),
}

export const ForecastAPI = {
    index: (params) => Axios.get('/forecast', { params: params }),
    get: ({ id }) => Axios.get(`/forecast/${id}`),
    post: ({ values }) => Axios.post(`/forecast/create`, values),
    patch: ({ id, values }) => Axios.patch(`/forecast/update/${id}`, values),
    delete: ({ id }) => Axios.delete(`/forecast/delete/${id}`),
}

export const ActivityAPI = {
    index: (params) => Axios.get('/activity', { params: params }),
    get: ({ id }) => Axios.get(`/activity/${id}`),
    post: ({ values }) => Axios.post(`/activity/create`, values),
    patch: ({ id, values }) => Axios.patch(`/activity/update/${id}`, values),
    delete: ({ id }) => Axios.delete(`/activity/delete/${id}`),
}

export const MilestoneAPI = {
    index: () => Axios.get('/milestone'),
    get: ({ id }) => Axios.get(`/milestone/${id}`),
    post: ({ values }) => Axios.post(`/milestone/create`, values),
    patch: ({ id, values }) => Axios.patch(`/milestone/update/${id}`, values),
    delete: ({ id }) => Axios.delete(`/milestone/delete/${id}`),
}

export const CustomerAPI = {
    index: () => Axios.get('/customer'),
    get: ({ id }) => Axios.get(`/customer/${id}`),
    post: ({ values }) => Axios.post(`/customer/create`, values),
    patch: ({ id, values }) => Axios.patch(`/customer/update/${id}`, values),
    delete: ({ id }) => Axios.delete(`/customer/delete/${id}`),
}

export const VatNumberAPI = {
    index: () => Axios.get('/vatnumber'),
    get: ({ id }) => Axios.get(`/vatnumber/${id}`),
    post: ({ values }) => Axios.post(`/vatnumber/create`, values),
    patch: ({ id, values }) => Axios.patch(`/vatnumber/update/${id}`, values),
    delete: ({ id }) => Axios.delete(`/vatnumber/delete/${id}`),
}

export const InvoiceAPI = {
    index: (params) => Axios.get('/invoice', { params: params }),
    get: ({ id }) => Axios.get(`/invoice/${id}`),
    post: ({ values }) => Axios.post(`/invoice/create`, values),
    patch: ({ id, values }) => Axios.patch(`/invoice/update/${id}`, values),
    delete: ({ id }) => Axios.delete(`/invoice/delete/${id}`),
}

export const ExpenseAPI = {
    index: (params) => Axios.get('/expense', { params: params }),
    get: ({ id }) => Axios.get(`/expense/${id}`),
    post: ({ values }) => Axios.post(`/expense/create`, values),
    patch: ({ id, values }) => Axios.patch(`/expense/update/${id}`, values),
    delete: ({ id }) => Axios.delete(`/expense/delete/${id}`),
}
export const AdjustmentAPI = {
    index: (params) => Axios.get('/adjustment', { params: params }),
    get: ({ id }) => Axios.get(`/adjustment/${id}`),
    post: ({ values }) => Axios.post(`/adjustment/create`, values),
    patch: ({ id, values }) => Axios.patch(`/adjustment/update/${id}`, values),
    delete: ({ id }) => Axios.delete(`/adjustment/delete/${id}`),
}

export const sumAPI = {
    invoice: (params) => Axios.get('/sum/invoice', { params: params }),
    expense: (params) => Axios.get('/sum/expense', { params: params }),
    adjustment: (params) => Axios.get('/sum/adjustment', { params: params }),
    log: (params) => Axios.get('/sum/log', { params: params }),
}

export const AuthAPI = {
    post: ({ values }) => Axios.post(`/auth/login`, values),
}
