import { createUseStyles } from 'react-jss'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Button, Col, Container, Nav, Row } from 'react-bootstrap'
import Input from '../../../components/Input'
import { useEffect, useState } from 'react'
import Select from '../../../components/Select'
import { useForm, useWatch } from 'react-hook-form'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectProjectStatus,
    selectProjectType,
    selectReferrals,
    storeProject,
    updateActivity,
} from '../../../store/slices/project'
import { ActivityAPI, ProjectAPI } from '../../../http-requests'
import {
    handleApiError,
    retrieveSingleValueForRs,
} from '../../../utilities/helpers'
import DatePicker from '../../../components/DatePicker'
import dayjs from 'dayjs'
import MaskedInput from '../../../components/MaskedInput'
import { setAlertMessage } from '../../../store/slices/app'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginTop: 32,
    },
    editIcons: {
        cursor: 'pointer',
        color: theme.palette.grey[400],
        '&:hover': {
            color: theme.palette.grey[600],
        },
    },
    tabsNavigation: {
        marginTop: 32,
    },
}))

const Project = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { projectID } = useParams()
    const referralOptions = useSelector(selectReferrals)
    const statusOptions = useSelector(selectProjectStatus)
    const typeOptions = useSelector(selectProjectType)
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        control,
        reset,
        formState: { touchedFields, errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            type: null,
            status: null,
        },
    })
    const [project, setProject] = useState()
    const [tab, setTab] = useState('activities')
    const projectType = useWatch({ control, name: 'type' })
    const hourly_rate = useWatch({ control, name: 'hourly_rate' })
    const projectTotalBudget = watch('total_budget', 0)
    const projectTotalHours = watch('total_hours', 0)

    const alignBudgetActivity = (hourly_rate) => {
        try {
            const { activity_set: list } = project
            for (let x = 0; x < list.length; x++) {
                const activityItem = { ...list[x] }
                activityItem.budget =
                    parseFloat(activityItem.total_hours) * hourly_rate
                editActivity(activityItem)
            }
        } catch (e) {}
    }

    const editActivity = async (activity) => {
        const { data: entityChanged } = await ActivityAPI.patch({
            id: activity.id,
            values: activity,
        })
        dispatch(updateActivity(entityChanged))
    }

    const setForm = (data) => {
        dispatch(storeProject(data))
        const projectData = {
            ...data,
            contingency: parseInt(data.contingency * 100),
            start_date: dayjs(data.start_date).toDate(),
            end_date: dayjs(data.end_date).toDate(),
            total_budget: parseFloat(data.total_budget),
            total_hours: parseFloat(data.total_hours),
            hourly_rate: parseFloat(data.hourly_rate),
        }
        setProject({ ...project, ...data })
        reset({ ...projectData })
        setValue('start_date', dayjs(data.start_date).toDate())
        setValue('end_date', dayjs(data.end_date).toDate())
        setValue('type', retrieveSingleValueForRs(typeOptions, data.type))
        setValue('status', retrieveSingleValueForRs(statusOptions, data.status))
        setValue(
            'referral',
            retrieveSingleValueForRs(referralOptions, data.referral)
        )
        //call this function for change the budget of activities
        alignBudgetActivity(data.hourly_rate)
    }

    useEffect(() => {
        if (pathname.includes('invoices')) {
            setTab('invoices')
        } else if (pathname.includes('expenses')) {
            setTab('expenses')
        } else if (pathname.includes('resume')) {
            setTab('resume')
        } else {
            setTab('activities')
        }
    }, [pathname])

    useEffect(async () => {
        const { data } = await ProjectAPI.get({ id: projectID })
        setForm(data)
    }, [])

    useEffect(() => {
        if (
            projectTotalBudget &&
            projectTotalHours &&
            ['Stimato', 'Pre-sale'].includes(projectType?.value)
        ) {
            const hourly_rate = projectTotalBudget / projectTotalHours || 0

            setValue('hourly_rate', parseFloat(hourly_rate).toFixed(2))
        }
    }, [projectTotalBudget, projectTotalHours])

    const onSubmit = async (values) => {
        const projectData = {
            ...values,
            start_date: dayjs(values.start_date).format('YYYY-MM-DD'),
            end_date: dayjs(values.end_date).format('YYYY-MM-DD'),
            status: values?.status?.value,
            type: values?.type?.value,
            referral: values?.referral?.value,
            total_budget:
                values?.type?.value !== 'Consuntivo' ? values.total_budget : 0,
            total_hours:
                values?.type?.value !== 'Consuntivo' ? values.total_hours : 0,
            contingency:
                values?.type?.value !== 'Consuntivo'
                    ? values.contingency > 100
                        ? 1
                        : values.contingency / 100
                    : 0,
            hourly_rate: parseFloat(values.hourly_rate),
        }
        try {
            const { data } = await ProjectAPI.patch({
                id: projectID,
                values: projectData,
            })
            setForm(data)
            dispatch(
                setAlertMessage({
                    variant: 'success',
                    message: 'Progetto salvato',
                })
            )
        } catch (e) {
            if (e.response.data) {
                handleApiError({
                    isReduxError: false,
                    error: e,
                    callbackOnFieldError: setError,
                })
            }
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message: 'Qualcosa non è andato nel verso giusto.',
                })
            )
        }
    }

    return project ? (
        <div className={classes.root}>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <h4>{project.title}</h4>
                            <p>
                                <small>{project.customer.business_name}</small>
                            </p>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button type={'submit'}>Salva</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <Select
                                name={'type'}
                                label="Tipologia"
                                errors={errors.type}
                                control={control}
                                options={typeOptions}
                            />
                        </Col>
                        <Col sm={3}>
                            <DatePicker
                                label="Data inizio"
                                errors={errors.start_date}
                                control={control}
                                name={'start_date'}
                            />
                        </Col>
                        <Col sm={3}>
                            <DatePicker
                                label="Data fine"
                                errors={errors.end_date}
                                control={control}
                                name={'end_date'}
                            />
                        </Col>
                        <Col sm={2}>
                            <Select
                                name={'status'}
                                label="Stato"
                                errors={errors.status}
                                control={control}
                                options={statusOptions}
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                currency={false}
                                decimal={false}
                                errors={errors.total_budget}
                                disabled={projectType?.value === 'Consuntivo'}
                                name={'contingency'}
                                label="Contingenza %"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                errors={errors.total_budget}
                                name={'total_budget'}
                                disabled={projectType?.value === 'Consuntivo'}
                                label="Budget"
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                name={'total_hours'}
                                label={'Totale ore'}
                                errors={errors.total_budget}
                                disabled={projectType?.value === 'Consuntivo'}
                                currency={false}
                                decimal={false}
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                name={'hourly_rate'}
                                errors={errors.total_budget}
                                currency={false}
                                disabled={projectType?.value !== 'Consuntivo'}
                                label="Costo orario"
                            />
                        </Col>
                        <Col sm={3}>
                            <Select
                                name={'referral'}
                                label="Referente"
                                control={control}
                                errors={errors.total_budget}
                                options={referralOptions}
                            />
                        </Col>
                        <Col sm={3}>
                            <Input
                                {...register('note')}
                                label="Link drive"
                                errors={errors.total_budget}
                            />
                        </Col>
                    </Row>
                </form>

                <div className={classes.tabsNavigation}>
                    <Nav
                        variant="tabs"
                        defaultActiveKey={`/projects/${project.id}`}
                    >
                        <Nav.Item>
                            <Nav.Link
                                active={tab === 'activities'}
                                as={Link}
                                to={`/projects/${project.id}`}
                            >
                                Attività di progetto
                            </Nav.Link>
                        </Nav.Item>
                        {project.type === 'Stimato' ? (
                            <Nav.Item>
                                <Nav.Link
                                    active={tab === 'invoices'}
                                    as={Link}
                                    to={`/projects/${project.id}/invoices`}
                                >
                                    Fatture
                                </Nav.Link>
                            </Nav.Item>
                        ) : null}
                        <Nav.Item>
                            <Nav.Link
                                active={tab === 'expenses'}
                                as={Link}
                                to={`/projects/${project.id}/expenses`}
                            >
                                Costi
                            </Nav.Link>
                        </Nav.Item>
                        {/*<Nav.Item>
                            <Nav.Link
                                active={tab === 'resume'}
                                as={Link}
                                to={`/projects/${project.id}/resume`}
                            >
                                Resoconto
                            </Nav.Link>
                        </Nav.Item>*/}
                    </Nav>
                </div>
                <Row>
                    <Outlet
                        context={{
                            projectID,
                        }}
                    />
                </Row>
            </Container>
        </div>
    ) : (
        'loading'
    )
}

export default Project
