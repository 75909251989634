import { BusinessUnitAPI, CustomerAPI, UserAPI } from '../http-requests'
import Axios from '../utilities/axios'
import {
    setSelectBusinessUnit,
    setSelectCustomers,
    setSelectReferral,
} from '../store/slices/project'
import store from '../store'

export const setSelectValues = async () => {
    const promiseUsers = new Promise(async (resolve, reject) => {
        let users = []
        let nextPage = true
        let nextUrl = false
        do {
            if (!nextUrl) {
                const {
                    data: { results, next },
                } = await UserAPI.index()
                users.push(...results)
                nextPage = next
                nextUrl = next
            } else {
                const {
                    data: { results, next },
                } = await Axios.get(nextUrl)
                users.push(...results)
                nextPage = next
                nextUrl = next
            }
        } while (nextPage)
        resolve(
            users.map((e) => {
                return {
                    value: e.id,
                    label: e.first_name
                        ? `${e.first_name} ${e.last_name}`
                        : e.username,
                }
            })
        )
    })

    const promiseBU = new Promise(async (resolve, reject) => {
        const {
            data: { results },
        } = await BusinessUnitAPI.index()
        resolve(
            results.map((e) => {
                return {
                    value: e.id,
                    label: e.code,
                }
            })
        )
    })

    const promiseCustomers = new Promise(async (resolve, reject) => {
        let customerData = []
        let nextPage = true
        let nextUrl = false
        do {
            if (!nextUrl) {
                const {
                    data: { results, next },
                } = await CustomerAPI.index()
                customerData.push(...results)
                nextPage = next
                nextUrl = next
            } else {
                const {
                    data: { results, next },
                } = await Axios.get(nextUrl)
                customerData.push(...results)
                nextPage = next
                nextUrl = next
            }
        } while (nextPage)
        resolve(
            customerData.map((e) => {
                return {
                    value: e.id,
                    label: e.business_name,
                }
            })
        )
    })

    Promise.all([promiseUsers, promiseBU, promiseCustomers]).then((values) => {
        const users = values[0]
        const businessUnits = values[1]
        const customers = values[2]
        store.dispatch(setSelectBusinessUnit(businessUnits))
        store.dispatch(setSelectReferral(users))
        store.dispatch(setSelectCustomers(customers))

        return true
    })
}
