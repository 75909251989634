import { createUseStyles } from 'react-jss'
import Input from '../../../components/Input'
import MaskedInput from '../../../components/MaskedInput'
import { useForm } from 'react-hook-form'
import { ForecastAPI } from '../../../http-requests'

const useStyles = createUseStyles((theme) => ({
    root: {},
    disabled: {
        background: `${theme.palette.erp.grey5}!important`,
    },
    input: {
        '& input': {
            border: 'none',
            padding: 0,
        },
    },
}))

const ProjectCell = ({
    forecast,
    customerID,
    forecastIndex,
    onChangeValue,
}) => {
    const classes = useStyles()
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        control,
        reset,
        formState: { touchedFields, errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            budget: forecast.budget,
        },
    })

    const onSubmit = async (values) => {
        if (forecast.id) {
            const { data } = await ForecastAPI.patch({
                id: forecast.id,
                values: {
                    budget: values.budget,
                },
            })
            onChangeValue({ forecast: data, customerID, forecastIndex })
        } else {
            const { data } = await ForecastAPI.post({
                values: {
                    budget: values.budget,
                    date: forecast.date,
                    project: forecast.project,
                },
            })
            onChangeValue({ forecast: data, customerID, forecastIndex })
        }
    }
    return forecast.disabled ? (
        <td className={classes.disabled}></td>
    ) : (
        <td className={classes.input}>
            <form onSubmit={handleSubmit(onSubmit)}></form>
            <MaskedInput
                control={control}
                onBlur={(event) => {
                    const val = getValues()
                    const sub = (val) => onSubmit(val)
                    handleSubmit(sub(val), event)
                }}
                name={'budget'}
            />
        </td>
    )
}

export default ProjectCell
