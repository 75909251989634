import { createUseStyles } from 'react-jss'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
    ActivityAPI,
    CustomerAPI,
    ExpenseAPI,
    InvoiceAPI,
} from '../../../../http-requests'
import { useOutletContext } from 'react-router-dom'
import { setAlertMessage } from '../../../../store/slices/app'
import ProjectExpense from './expense'
import DatePickerInput from '../../../../components/DatePicker'
import MaskedInput from '../../../../components/MaskedInput'
import dayjs from 'dayjs'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 32,
    },
    activityHeader: {
        background: theme.palette.erp.grey4,
        padding: [8, 16, 16],
    },
    save: {
        marginTop: 24,
        textAlign: 'right',
    },
}))
const ProjectExpenses = () => {
    const classes = useStyles()
    const { projectID } = useOutletContext()
    const dispatch = useDispatch()
    const [expenses, setExpenses] = useState([])
    const [activities, setActivities] = useState([])
    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
    })
    const { register, handleSubmit, setValue, control, reset } = formMethods

    useEffect(() => {
        async function fetchData() {
            try {
                const {
                    data: { results: expensesList },
                } = await ExpenseAPI.index({ project: projectID })

                const {
                    data: { results: activitiesList },
                } = await ActivityAPI.index({ project: projectID })
                setExpenses(expensesList)
                setActivities(
                    activitiesList.map((e) => {
                        return {
                            value: e.id,
                            label: `#${e.id} - ${e.title}`,
                        }
                    })
                )
            } catch (e) {
                console.error(e)
                dispatch(
                    setAlertMessage({
                        variant: 'fail',
                        message:
                            'Non siamo riusciti a caricare i costi. Qualcosa è andato storto.',
                    })
                )
            }
        }

        fetchData()
    }, [projectID])

    const onSubmit = async (values) => {
        try {
            const payload = {
                ...values,
                activity: values.activity.value,
                date: dayjs(values.date).format('YYYY-MM-DD'),
            }
            const { data } = await ExpenseAPI.post({ values: payload })
            setExpenses((prev) => [...prev, data])
            setValue('amount', 0)
            setValue('description', '')
            setValue('financial_ref', '')
            reset()
        } catch (e) {
            console.error(e)
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message:
                        'Non siamo riusciti a salvare il costo. Qualcosa è andato storto.',
                })
            )
        }
    }
    const onExpenseDelete = async (id) => {
        try {
            const { data } = ExpenseAPI.delete({ id })
            const index = expenses.findIndex((e) => e.id === id)
            const newExpensesList = [...expenses]
            newExpensesList.splice(index, 1)
            setExpenses(newExpensesList)
        } catch (e) {
            console.error(e)
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.activityHeader}>
                <form onSubmit={handleSubmit((values) => onSubmit(values))}>
                    <Row>
                        <Col sm={2}>
                            <DatePickerInput
                                control={control}
                                label={'Data'}
                                name={'date'}
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                name={'amount'}
                                label="Imponibile"
                            />
                        </Col>
                        <Col sm={3}>
                            <Select
                                name={'activity'}
                                label="Attività"
                                control={control}
                                options={activities}
                            />
                        </Col>
                        <Col sm={3}>
                            <Input
                                {...register('description')}
                                label="Descrizione"
                            />
                        </Col>
                        <Col sm={2}>
                            <Input
                                {...register('financial_ref')}
                                label="Rif. finanziario"
                            />
                        </Col>
                    </Row>
                    <div className={classes.save}>
                        <Button type="submit" size="sm">
                            Salva
                        </Button>
                    </div>
                </form>
            </div>
            {activities.length && expenses.length
                ? expenses.map((expense, index) => (
                      <ProjectExpense
                          key={index}
                          projectID={projectID}
                          activities={activities}
                          onDelete={onExpenseDelete}
                          expense={expense}
                      />
                  ))
                : null}
        </div>
    )
}

export default ProjectExpenses
