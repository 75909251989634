import { createUseStyles } from 'react-jss'
import { Col, Row } from 'react-bootstrap'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { useForm } from 'react-hook-form'
import { selectInvoiceStatus } from '../../../../store/slices/project'
import { useDispatch, useSelector } from 'react-redux'
import { InvoiceAPI } from '../../../../http-requests'
import { Link, useOutletContext } from 'react-router-dom'
import MaskedInput from '../../../../components/MaskedInput'
import DatePickerInput from '../../../../components/DatePicker'
import dayjs from 'dayjs'
import { retrieveSingleValueForRs } from '../../../../utilities/helpers'
import { FaTrash } from 'react-icons/fa'
import { ROUTE_CUSTOMER, ROUTE_PROJECT } from '../../../../utilities/constants'
import { setAlertMessage } from '../../../../store/slices/app'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 16,
        paddingBottom: 16,
        borderBottom: `2px solid ${theme.palette.erp.grey4}`,
    },
    activityHeader: {
        padding: [8, 16, 16],
    },
    save: {
        marginTop: 24,
        textAlign: 'right',
    },
    paymentDate: {
        marginBottom: 14,
        fontSize: 14,
    },
    iconDelete: {
        marginBottom: 14,
        fontSize: 14,
        color: theme.palette.erp.violet1,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.erp.violet2,
        },
    },
    info: {
        '& a:first-child': {
            paddingRight: 16,
        },
        '& a:nth-child(2):before': {
            content: "'/'",
            position: 'relative',
            left: -8,
        },
    },
}))
const Invoice = ({ invoice, onDelete, heading = false }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const statusOptions = useSelector(selectInvoiceStatus)
    const customer = invoice.customer
        ? invoice.customer.id
            ? invoice.customer.id
            : invoice.customer
        : null
    const project = invoice.project
        ? invoice.project.id
            ? invoice.project.id
            : invoice.project
        : null
    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            ...invoice,
            date: invoice.date ? dayjs(invoice.date).toDate() : null,
            payment_date: invoice.payment_date
                ? dayjs(invoice.payment_date).toDate()
                : null,
            status: retrieveSingleValueForRs(statusOptions, invoice.status),
        },
    })
    const {
        register,
        handleSubmit,
        setError,
        control,
        getValues,
        formState: { touchedFields, errors, isSubmitting },
    } = formMethods

    const onSubmit = async (formData) => {
        const paymentDate = invoice.payment_date
            ? invoice.payment_date
            : formData.status.value === 'Pagata'
            ? dayjs().format('YYYY-MM-DD')
            : null
            ? dayjs(formData.payment_date).format('YYYY-MM-DD')
            : null
        const values = {
            ...formData,
            date: formData.date
                ? dayjs(formData.date).format('YYYY-MM-DD')
                : null,
            payment_date: paymentDate,
            status: formData.status.value,
            customer,
            project: project,
        }
        try {
            const { data } = await InvoiceAPI.patch({
                id: invoice.id,
                values,
            })
            dispatch(
                setAlertMessage({
                    variant: 'success',
                    message: 'Fattura salvata',
                })
            )
        } catch (e) {
            console.error(e)
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message:
                        'Non siamo riusciti ad aggiornare la fattura. Qualcosa è andato storto.',
                })
            )
        }
    }

    const onClickDelete = () => {
        onDelete(invoice.id)
    }

    return (
        <div className={classes.root}>
            <div className={classes.activityHeader}>
                {heading ? (
                    <Row>
                        <Col sm={12} className={classes.info}>
                            {invoice?.customer?.business_name ? (
                                <Link
                                    to={ROUTE_CUSTOMER.replace(
                                        ':customerID',
                                        invoice?.customer?.id
                                    )}
                                >
                                    {invoice?.customer?.business_name}
                                </Link>
                            ) : (
                                ''
                            )}
                            {invoice?.project?.title ? (
                                <Link
                                    to={ROUTE_PROJECT.replace(
                                        ':projectID',
                                        invoice?.project?.id
                                    )}
                                >
                                    {invoice?.project?.title}
                                </Link>
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>
                ) : null}

                <form onSubmit={handleSubmit((values) => onSubmit(values))}>
                    <Row className="align-items-end">
                        <Col sm={!project ? 2 : 3}>
                            <DatePickerInput
                                control={control}
                                onValueChanged={(value) => {
                                    const item = getValues()
                                    item.date = value
                                    const sub = (item) => onSubmit(item)
                                    handleSubmit(sub(item))
                                }}
                                label={'Data emissione'}
                                name={'date'}
                            />
                        </Col>

                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                name={'amount'}
                                label="Imponibile"
                                onBlur={(event) => {
                                    const val = getValues()
                                    const sub = (val) => onSubmit(val)
                                    handleSubmit(sub(val), event)
                                }}
                            />
                        </Col>
                        {!project ? (
                            <Col sm={2}>
                                <MaskedInput
                                    control={control}
                                    currency={false}
                                    decimal={false}
                                    onBlur={(event) => {
                                        const val = getValues()
                                        const sub = (val) => onSubmit(val)
                                        handleSubmit(sub(val), event)
                                    }}
                                    name={'total_hours'}
                                    label="Ore"
                                />
                            </Col>
                        ) : null}
                        <Col sm={2}>
                            <Select
                                name="status"
                                label="Status"
                                control={control}
                                options={statusOptions}
                                onBlur={(event) => {
                                    const val = getValues()
                                    const sub = (val) => onSubmit(val)
                                    handleSubmit(sub(val), event)
                                }}
                            />
                        </Col>
                        <Col sm={!project ? 3 : 4}>
                            <Input
                                {...register('description')}
                                label="Descrizione"
                                onBlur={(event) => {
                                    const val = getValues()
                                    const sub = (val) => onSubmit(val)
                                    handleSubmit(sub(val), event)
                                }}
                            />
                        </Col>
                        <Col sm={1}>
                            <div className={classes.iconDelete}>
                                {onDelete ? (
                                    <FaTrash onClick={onClickDelete} />
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default Invoice
