import { createUseStyles } from 'react-jss'
import moment from 'moment'
import NumberFormat from 'react-number-format'

const useStyles = createUseStyles((theme) => ({
    root: {},
}))

const CustomerCell = ({ projectMap, dateRef }) => {
    const classes = useStyles()
    const calculateTotal = () => {
        const p = Object.keys(projectMap)
        let total = 0
        for (let x = 0; x < p.length; x++) {
            const forecasts = projectMap[p[x]].forecast
            const forecast = forecasts.filter(
                (item) =>
                    moment(item.date, 'YYYY-MM-DD').get('month') ===
                    dateRef.get('month')
            )
            const sumForecast = forecast.reduce(
                (a, b) => (b.budget ? a + b.budget : a),
                0
            )
            total += sumForecast
        }
        return total
    }
    return (
        <td>
            {' '}
            <NumberFormat
                value={calculateTotal()}
                thousandSeparator="."
                decimalSeparator=","
                prefix="€"
                displayType={'text'}
                thousandsGroupStyle="thousand"
            />
        </td>
    )
}

export default CustomerCell
