import { createUseStyles } from 'react-jss'
import LogBar from '../../components/LogBar'
import useLocationChange from '../../hooks/useLocationChange'
import { Container } from 'react-bootstrap'

const useStyles = createUseStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
}))

const SharedLayout = ({ children }) => {
    const classes = useStyles()
    useLocationChange((location) => {
        //console.log('handle route change here', location)
    })

    return (
        <div className={classes.root}>
            <Container fluid>{children}</Container>
            {/*{process.env.NODE_ENV === 'development' && <LogBar />}*/}
        </div>
    )
}

export default SharedLayout
