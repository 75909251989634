import { combineReducers, configureStore } from '@reduxjs/toolkit'
import appReducer from './slices/app'
import projectReducer from './slices/project'

const combinedReducer = combineReducers({
    app: appReducer,
    project: projectReducer,
})

const rootReducer = (state, action) => {
    return combinedReducer(state, action)
}

const store = configureStore({
    reducer: rootReducer,
})

export default store
