import { createUseStyles } from 'react-jss'
import { Button, Col, Row, Table } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import TextArea from '../../../components/TextArea'
import { CustomerAPI } from '../../../http-requests'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CUSTOMER } from '../../../utilities/constants'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: [0, 16, 16],
    },
    save: {
        marginTop: 16,
        textAlign: 'center',
    },
}))

const NewCustomer = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { register, handleSubmit, setValue, getValues } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            business_name: '',
            note: '',
        },
    })
    const onSubmit = async (values) => {
        try {
            const { data } = await CustomerAPI.post({ values })
            navigate(ROUTE_CUSTOMER.replace(':customerID', data.id))
        } catch (e) {
            console.error(e)
        }
    }
    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit((values) => onSubmit(values))}>
                <TextArea
                    label={'Ragione sociale'}
                    {...register('business_name')}
                />
                <TextArea label={'note'} {...register('note')} />
                <div className={classes.save}>
                    <Button type={'submit'}>Salva</Button>
                </div>
            </form>
        </div>
    )
}

export default NewCustomer
