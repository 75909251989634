import { useSortBy, useTable } from 'react-table'
import { Table } from 'react-bootstrap'
import {
    TiArrowSortedDown,
    TiArrowSortedUp,
    TiArrowUnsorted,
} from 'react-icons/ti'
import InfiniteScroll from 'react-infinite-scroll-component'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    root: {},
}))

const ReactTable = ({ columns, data, hasMore, update }) => {
    const classes = useStyles()
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useSortBy)

    return (
        <InfiniteScroll
            dataLength={rows.length}
            next={update}
            hasMore={hasMore}
            loader={<p>Loading...</p>}
        >
            <Table
                className={classes.root}
                striped
                bordered
                hover
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <TiArrowSortedDown />
                                            ) : (
                                                <TiArrowSortedUp />
                                            )
                                        ) : (
                                            <TiArrowUnsorted />
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </InfiniteScroll>
    )
}

export default ReactTable
