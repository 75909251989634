export const ROUTE_HOME = '/'
export const ROUTE_LOGIN = '/login'
/*
 * Projects route
 * */
export const ROUTE_PROJECTS = '/projects'
export const ROUTE_PROJECT_CREATE = '/projects/create'
export const ROUTE_PROJECT = '/projects/:projectID'

export const ROUTE_CONSUMPTIVES = '/consumptives'

/*
 * Customers route
 * */
export const ROUTE_CUSTOMERS = '/customers'
export const ROUTE_CUSTOMER_CREATE = '/customers/create'
export const ROUTE_CUSTOMER = '/customers/:customerID'

export const ROUTE_SCHEDULE = '/schedule'
