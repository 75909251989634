import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { InvoiceAPI } from '../../../../http-requests'
import { setAlertMessage } from '../../../../store/slices/app'
import { useDispatch, useSelector } from 'react-redux'
import Invoice from '../../../projects/project/projectInvoices/invoice'
import DatePickerInput from '../../../../components/DatePicker'
import MaskedInput from '../../../../components/MaskedInput'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { selectInvoiceStatus } from '../../../../store/slices/project'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 32,
    },
    activityHeader: {
        background: theme.palette.erp.grey4,
        padding: [8, 16, 16],
    },
    save: {
        marginTop: 24,
        textAlign: 'right',
    },
}))

const CustomerExpenses = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { customerID } = useParams()
    const statusOptions = useSelector(selectInvoiceStatus)
    const [invoices, setInvoices] = useState([])
    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
    })
    const {
        register,
        handleSubmit,
        setError,
        control,
        reset,
        setValue,
        getValues,
        formState: { touchedFields, errors, isSubmitting },
    } = formMethods

    useEffect(async () => {
        const fetchData = async () => {
            try {
                const {
                    data: { results },
                } = await InvoiceAPI.index({
                    without_project: true,
                    customer: customerID,
                })
                setInvoices(results)
            } catch (e) {
                console.error(e)
                dispatch(
                    setAlertMessage({
                        variant: 'fail',
                        message:
                            'Non siamo riusciti a caricare le fatture. Qualcosa è andato storto.',
                    })
                )
            }
        }
        fetchData()
    }, [])
    const onSubmit = async (formData) => {
        const values = {
            ...formData,
            customer: customerID,
            date: formData.date
                ? dayjs(formData.date).format('YYYY-MM-DD')
                : null,
            payment_date: formData.payment_date
                ? dayjs(formData.payment_date).format('YYYY-MM-DD')
                : null,
            status: formData.status.value,
        }
        try {
            const { data } = await InvoiceAPI.post({
                values,
            })
            setInvoices((prevState) => [...prevState, data])
            reset()
            setValue('amount', 0)
        } catch (e) {
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message: 'Non siamo riusciti a creare la fattura.',
                })
            )
            console.error(e)
        }
    }
    const onInvoiceDelete = async (id) => {
        try {
            const { data } = InvoiceAPI.delete({ id })
            const index = invoices.findIndex((e) => e.id === id)
            const newInvoiceList = [...invoices]
            newInvoiceList.splice(index, 1)
            setInvoices(newInvoiceList)
        } catch (e) {
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message: 'Fattura non cancellata.',
                })
            )
            console.error(e)
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.activityHeader}>
                <form onSubmit={handleSubmit((values) => onSubmit(values))}>
                    <Row className="align-items-center">
                        <Col sm={2}>
                            <DatePickerInput
                                control={control}
                                label={'Data emissione'}
                                name={'date'}
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                name={'amount'}
                                label="Imponibile"
                            />
                        </Col>
                        <Col sm={2}>
                            <MaskedInput
                                control={control}
                                currency={false}
                                decimal={false}
                                name={'total_hours'}
                                label="Ore"
                            />
                        </Col>

                        <Col sm={2}>
                            <Select
                                name="status"
                                label="Status"
                                control={control}
                                options={statusOptions}
                            />
                        </Col>
                        <Col sm={3}>
                            <Input
                                {...register('description')}
                                label="Descrizione"
                            />
                        </Col>
                        <Col sm={1}>
                            <div className={classes.save}>
                                <Button type="submit" size="sm">
                                    Salva
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
            {invoices.length
                ? invoices.map((e) => (
                      <Invoice
                          key={e.id}
                          invoice={e}
                          onDelete={onInvoiceDelete}
                      />
                  ))
                : null}
        </div>
    )
}

export default CustomerExpenses
