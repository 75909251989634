import { LOCAL_STORAGE_ACCESS_TOKEN } from './constants'

const accessToken = {
    set: (value) => {
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, value)
    },
    get: () => {
        return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
    },
}

const setAuthData = (token) => {
    accessToken.set(token)
}

const removeAuthData = () => {
    accessToken.remove()
}

const LocalStorageManager = {
    accessToken,
    setAuthData,
    removeAuthData,
}

export default LocalStorageManager
