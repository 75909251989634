import { Link, Navigate, Outlet } from 'react-router-dom'
import {
    ROUTE_CONSUMPTIVES,
    ROUTE_CUSTOMERS,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_PROJECTS,
    ROUTE_SCHEDULE,
} from '../../utilities/constants'
import PrivateLayout from '../../layouts/private-layout'
import { Container, Nav, Navbar, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectAuthToken } from '../../store/slices/app'
import { useEffect } from 'react'
import { setSelectValues } from '../../utilities/getSelectValues'
import { selectReferrals } from '../../store/slices/project'

const PrivateRoute = () => {
    const accessToken = useSelector(selectAuthToken) // TODO: add auth logic
    const referralOptions = useSelector(selectReferrals)

    useEffect(async () => {
        if (accessToken) {
            const response = await setSelectValues()
        }
    }, [accessToken])

    return !accessToken ? (
        <Navigate to={ROUTE_LOGIN} />
    ) : referralOptions.length ? (
        <PrivateLayout>
            <Row>
                <Navbar bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand as={Link} to={ROUTE_HOME}>
                            ERP Client
                        </Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to={ROUTE_PROJECTS}>
                                Progetti
                            </Nav.Link>
                            <Nav.Link as={Link} to={ROUTE_CONSUMPTIVES}>
                                Forecast consuntivi
                            </Nav.Link>
                            <Nav.Link as={Link} to={ROUTE_CUSTOMERS}>
                                Clienti
                            </Nav.Link>
                            <Nav.Link as={Link} to={ROUTE_SCHEDULE}>
                                Scadenzario
                            </Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </Row>
            <Outlet />
        </PrivateLayout>
    ) : (
        <p>loading</p>
    )
}

export default PrivateRoute
