import { createUseStyles } from 'react-jss'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import { useForm } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'
import Milestone from './milestone'
import { retrieveSingleValueForRs } from '../../../../utilities/helpers'
import { useDispatch, useSelector } from 'react-redux'
import {
    removeActivity,
    selectActivityStatus,
    selectBusinessUnit,
    updateActivity,
} from '../../../../store/slices/project'
import DatePickerInput from '../../../../components/DatePicker'
import dayjs from 'dayjs'
import { ActivityAPI } from '../../../../http-requests'
import MaskedInput from '../../../../components/MaskedInput'
import { setAlertMessage } from '../../../../store/slices/app'

const useStyles = createUseStyles((theme) => ({
    root: {
        marginBottom: 24,
    },
    activityWrapper: {
        marginBottom: 8,
        border: `1px solid ${theme.palette.erp.grey4}`,
    },
    activityHeader: {
        background: theme.palette.erp.grey4,
        padding: [8, 16, 16],
    },
    deleteRow: {
        textAlign: 'right',
        padding: [0, 24],
        '& span': {
            color: theme.palette.error.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
}))

const Activity = ({
    activity,
    projectID,
    smallFeatures = false,
    hourly_rate = 1,
    totalBudget = 0,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const spreadRef = useRef()
    const statusOptions = useSelector(selectActivityStatus)
    const BUOptions = useSelector(selectBusinessUnit)

    const defaultValues = (values) => {
        return {
            title: values.title,
            budget: values.budget,
            business_unit: retrieveSingleValueForRs(
                BUOptions,
                values.business_unit
            ),
            end_date: dayjs(values.end_date).toDate(),
            start_date: dayjs(values.start_date).toDate(),
            status: retrieveSingleValueForRs(statusOptions, values.status),
            total_hours: values.total_hours,
        }
    }

    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: defaultValues(activity),
    })
    const {
        register,
        handleSubmit,
        watch,
        setError,
        control,
        setValue,
        getValues,
        reset,
        formState: { touchedFields, errors, isSubmitting },
    } = formMethods

    const activityBudget = watch('budget', 0)

    useEffect(() => {
        reset(defaultValues(activity))
    }, [activity])

    useEffect(() => {
        if (activityBudget > 0)
            spreadRef.current.value = parseFloat(
                (activityBudget / totalBudget) * 100
            ).toFixed(2)
    }, [activityBudget, totalBudget])

    const onSubmit = async (values, id) => {
        try {
            const data = {
                title: values.title,
                budget: values.budget,
                business_unit: values.business_unit.value,
                end_date: dayjs(values.end_date).format('YYYY-MM-DD'),
                start_date: dayjs(values.start_date).format('YYYY-MM-DD'),
                status: values.status.value,
                total_hours: values.total_hours,
            }
            const { data: entityChanged } = await ActivityAPI.patch({
                id: activity.id,
                values: data,
            })
            dispatch(updateActivity(entityChanged))
            dispatch(
                setAlertMessage({
                    variant: 'success',
                    message: 'Attività salvata',
                })
            )
        } catch (e) {
            console.error(e)
            dispatch(
                setAlertMessage({
                    variant: 'fail',
                    message:
                        "L'attività non è salta salvata. Qualcosa non è andata nel verso giusto.",
                })
            )
        }
    }
    const onDelete = async () => {
        try {
            const { data } = await ActivityAPI.delete({
                id: activity.id,
            })
            dispatch(removeActivity(activity.id))
        } catch (e) {
            console.error(e)
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.activityWrapper}>
                <div className={classes.activityHeader}>
                    <form
                        onSubmit={handleSubmit((values) =>
                            onSubmit(values, projectID)
                        )}
                    >
                        <Row>
                            <Col sm={smallFeatures ? 4 : 2}>
                                <Input
                                    {...register('title')}
                                    onBlur={(event) => {
                                        const val = getValues()
                                        const sub = (val) =>
                                            onSubmit(val, projectID)
                                        handleSubmit(sub(val), event)
                                    }}
                                    label="Nome attività"
                                />
                            </Col>
                            {!smallFeatures ? (
                                <Col sm={2}>
                                    <MaskedInput
                                        control={control}
                                        name={'budget'}
                                        label="Budget"
                                        onValueChanged={(value) => {
                                            setValue(
                                                'total_hours',
                                                parseInt(value / hourly_rate)
                                            )
                                        }}
                                        onBlur={(event) => {
                                            const val = getValues()
                                            const sub = (val) =>
                                                onSubmit(val, projectID)
                                            handleSubmit(sub(val), event)
                                        }}
                                    />
                                </Col>
                            ) : null}
                            {!smallFeatures ? (
                                <Col sm={1}>
                                    <MaskedInput
                                        control={control}
                                        name={'total_hours'}
                                        label="Ore"
                                        currency={false}
                                        decimal={false}
                                        onValueChanged={(value) => {
                                            setValue(
                                                'budget',
                                                parseFloat(
                                                    value * hourly_rate
                                                ).toFixed(2)
                                            )
                                        }}
                                        onBlur={(event) => {
                                            const val = getValues()
                                            const sub = (val) =>
                                                onSubmit(val, projectID)
                                            handleSubmit(sub(val), event)
                                        }}
                                    />
                                </Col>
                            ) : null}
                            <Col sm={smallFeatures ? 2 : 1}>
                                <Select
                                    onBlur={(event) => {
                                        const val = getValues()
                                        const sub = (val) =>
                                            onSubmit(val, projectID)
                                        handleSubmit(sub(val), event)
                                    }}
                                    name={'business_unit'}
                                    label="B. Unit"
                                    control={control}
                                    options={BUOptions}
                                />
                            </Col>
                            <Col sm={2}>
                                <DatePickerInput
                                    control={control}
                                    onValueChanged={(value) => {
                                        const item = getValues()
                                        item.start_date = value
                                        const sub = (item) =>
                                            onSubmit(item, projectID)
                                        handleSubmit(sub(item))
                                    }}
                                    label={'Inizio'}
                                    name={'start_date'}
                                />
                            </Col>
                            <Col sm={2}>
                                <DatePickerInput
                                    control={control}
                                    onValueChanged={(value) => {
                                        const item = getValues()
                                        item.end_date = value
                                        const sub = (item) =>
                                            onSubmit(item, projectID)
                                        handleSubmit(sub(item))
                                    }}
                                    label={'Fine'}
                                    name={'end_date'}
                                />
                            </Col>
                            {!smallFeatures ? (
                                <Col sm={1}>
                                    <Input
                                        disabled={true}
                                        label="Incidenza"
                                        ref={spreadRef}
                                    />
                                </Col>
                            ) : null}
                            <Col sm={smallFeatures ? 2 : 1}>
                                <Select
                                    name="status"
                                    onBlur={(event) => {
                                        const val = getValues()
                                        const sub = (val) =>
                                            onSubmit(val, projectID)
                                        handleSubmit(sub(val), event)
                                    }}
                                    label="Status"
                                    control={control}
                                    options={statusOptions}
                                />
                            </Col>
                        </Row>
                    </form>
                </div>
                {!smallFeatures ? (
                    <Milestone
                        elements={activity.milestone_set}
                        activityID={activity.id}
                    />
                ) : null}
            </div>
            <div className={classes.deleteRow}>
                <span onClick={onDelete}>Elimina</span>
            </div>
        </div>
    )
}

export default Activity
