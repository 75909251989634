import { createSelector, createSlice } from '@reduxjs/toolkit'

const sliceName = 'project'

const projectSlice = createSlice({
    name: sliceName,
    initialState: {
        select: {
            projectType: [
                { value: 'Consuntivo', label: 'Consuntivo' },
                { value: 'Stimato', label: 'Stimato' },
                { value: 'Pre-Sale', label: 'Pre-Sale' },
            ],
            projectStatus: [
                { value: 'Aperto', label: 'Aperto' },
                { value: 'Chiuso', label: 'Chiuso' },
            ],
            referrals: [],
            businessUnit: [],
            customers: [],
            activityStatus: [
                { value: 'Aperto', label: 'Aperto' },
                { value: 'Chiuso', label: 'Chiuso' },
            ],
            invoiceStatus: [
                { value: 'Prevista', label: 'Prevista' },
                { value: 'Emessa', label: 'Emessa' },
                { value: 'Pagata', label: 'Pagata' },
                { value: 'UTT', label: 'UTT' },
                { value: 'Annullata', label: 'Annullata' },
            ],
        },
        project: {},
    },
    reducers: {
        setSelectOptions: (state, action) => {
            state.select.projectType = action.payload.projectType
            state.select.projectStatus = action.payload.projectStatus
            state.select.referrals = action.payload.referrals
            state.select.businessUnit = action.payload.businessUnit
            state.select.activityStatus = action.payload.activityStatus
            state.select.invoiceStatus = action.payload.invoiceStatus
        },
        setSelectReferral: (state, action) => {
            state.select.referrals = action.payload
        },
        setSelectBusinessUnit: (state, action) => {
            state.select.businessUnit = action.payload
        },
        setSelectCustomers: (state, action) => {
            state.select.customers = action.payload
        },
        storeProject: (state, action) => {
            const project = { ...state.project, ...action.payload }
            state.project = project
        },
        updateActivity: (state, action) => {
            const index = state.project.activity_set.findIndex(
                (e) => parseInt(e.id) === parseInt(action.payload.id)
            )

            const project = {
                ...state.project,
            }
            project.activity_set[index] = {
                ...project.activity_set[index],
                ...action.payload,
            }
            state.project = project
        },
        addActivity: (state, action) => {
            const project = { ...state.project }
            project.activity_set.push(action.payload)
            state.project = project
        },
        removeActivity: (state, action) => {
            const index = state.project.activity_set.findIndex(
                (e) => parseInt(e.id) === parseInt(action.payload)
            )

            const project = {
                ...state.project,
            }
            project.activity_set.splice(index, 1)
            state.project = project
        },
        updateMilestone: (state, action) => {
            const { activityID, milestoneID, milestone } = action.payload

            const activityIndex = state.project.activity_set.findIndex(
                (e) => parseInt(e.id) === parseInt(activityID)
            )

            const milestoneIndex = state.project.activity_set[
                activityIndex
            ].milestone_set.findIndex(
                (e) => parseInt(e.id) === parseInt(milestoneID)
            )

            const project = {
                ...state.project,
            }
            project.activity_set[activityIndex].milestone_set[milestoneIndex] =
                {
                    ...project.activity_set[activityIndex].milestone_set[
                        milestoneIndex
                    ],
                    ...milestone,
                }
            state.project = project
        },
        addMilestone: (state, action) => {
            const { activityID, milestone } = action.payload

            const activityIndex = state.project.activity_set.findIndex(
                (e) => parseInt(e.id) === parseInt(activityID)
            )
            const project = { ...state.project }
            project.activity_set[activityIndex].milestone_set.push(milestone)
            state.project = project
        },
        removeMilestone: (state, action) => {
            const { activityID, milestoneID } = action.payload

            const activityIndex = state.project.activity_set.findIndex(
                (e) => parseInt(e.id) === parseInt(activityID)
            )

            const milestoneIndex = state.project.activity_set[
                activityIndex
            ].milestone_set.findIndex(
                (e) => parseInt(e.id) === parseInt(milestoneID)
            )

            const project = {
                ...state.project,
            }
            project.activity_set[activityIndex].milestone_set.splice(
                milestoneIndex,
                1
            )
            state.project = project
        },
    },
})

// Selectors
const selectSelf = (state) => state[sliceName]
export const selectDefaultSelectData = createSelector(
    selectSelf,
    (state) => state.select
)
export const selectProjectType = createSelector(
    selectSelf,
    (state) => state.select.projectType
)
export const selectProjectStatus = createSelector(
    selectSelf,
    (state) => state.select.projectStatus
)
export const selectReferrals = createSelector(
    selectSelf,
    (state) => state.select.referrals
)
export const selectCustomers = createSelector(
    selectSelf,
    (state) => state.select.customers
)
export const selectBusinessUnit = createSelector(
    selectSelf,
    (state) => state.select.businessUnit
)
export const selectActivityStatus = createSelector(
    selectSelf,
    (state) => state.select.activityStatus
)
export const selectInvoiceStatus = createSelector(
    selectSelf,
    (state) => state.select.invoiceStatus
)
export const getCurrentProject = createSelector(
    selectSelf,
    (state) => state.project
)

export const {
    setSelectOptions,
    setSelectReferral,
    setSelectBusinessUnit,
    storeProject,
    removeActivity,
    updateActivity,
    setSelectCustomers,
    addActivity,
    updateMilestone,
    addMilestone,
    removeMilestone,
} = projectSlice.actions

export default projectSlice.reducer
